import {
  TitlePracticeAddressRow,
  PracticeName,
  PracticeAddress,
  PhoneNumber,
  FaxNumber,
  Spi,
  ServiceLevel,
  Status,
  SupervisingPhysician,
} from './StyledComponents'

type TitleRowType = { isVet: boolean }
const TitleRow = ({ isVet }: TitleRowType): JSX.Element => {
  return (
    <TitlePracticeAddressRow>
      <PracticeName>Practice name</PracticeName>
      <PracticeAddress>Address</PracticeAddress>
      <PhoneNumber>Phone number</PhoneNumber>
      <FaxNumber>Fax number</FaxNumber>
      {isVet ? '' : <Spi>SPI</Spi>}
      {isVet ? '' : <ServiceLevel>Service Level</ServiceLevel>}
      <SupervisingPhysician>Supervising Physician</SupervisingPhysician>
      {isVet ? '' : <Status>Status</Status>}
    </TitlePracticeAddressRow>
  )
}

export default TitleRow
