import { useState } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import QueuesSummary from 'components/QueuesSummary'
import { gql } from 'gql/generated'
import useErrorToast from 'hooks/toast/useErrorToast'
import moment from 'moment'
import { usePlusClient } from 'providers/VisionRouter'

const GET_OTC_ONLY_ORDERS = gql(`
query getOtcOnlyOrders($locationId: ID, $customerId: ID, $states: [String]) {
  getOtcOnlyOrders(locationId: $locationId, customerId: $customerId, states: $states) {
    Packing
  }
}
`)

const GET_FILLS_BY_STATUS = gql(`
query getFillsByStatus($locationId: ID, $customerId: ID, $states: [String]) {
  getFillsByStatus(locationId: $locationId, customerId: $customerId, states: $states) {
    PV1 {
      total
      between24And48Hours
      greaterThan48Hours
    }
    Fill {
      total
      between24And48Hours
      greaterThan48Hours
    }
    Automation {
      total
      between24And48Hours
      greaterThan48Hours
    }
    PV2 {
      total
      between24And48Hours
      greaterThan48Hours
    }
    Adjudication {
      total
      between24And48Hours
      greaterThan48Hours
    }
    PriorAuthorization {
      total
      between24And48Hours
      greaterThan48Hours
    }
  }
}
`)

const GET_ORDERS_BY_STATUS = gql(`
query getOrdersByStatus($locationId: ID, $customerId: ID, $states: [String]) {
  getOrdersByStatus(locationId: $locationId, customerId: $customerId, states: $states) {
    packing
  }
}
`)

const GET_TRIAGES = gql(`
query getTriages($locationId: ID, $customerId: ID, $states: [String]) {
  getTriages(locationId: $locationId, customerId: $customerId, states: $states) {
    inTriage
  }
}
`)

const GET_TRANSFERS = gql(`
  query getTransfers($locationId: ID, $customerId: ID) {
    getTransfers(locationId: $locationId, customerId: $customerId) {
      FaxFailed
      ToTransfer
      NeedsVerification
    }
  }
`)

const getCurrentTime = () => moment().format('MM/DD/YYYY hh:mm:ssA')

const StatsSection = (): JSX.Element => {
  const [lastFetchTime, setLastFetchTime] = useState(getCurrentTime())
  const {
    currentLocation: { queryMap },
  } = usePlusClient()
  const showErrorToast = useErrorToast()

  /**
   * Filter does not allow for multi-select so we just grab the first
   * one if it exists
   */
  const customerId = queryMap.customerIds?.[0]

  const { data: otcOnlyOrders } = useQuery(GET_OTC_ONLY_ORDERS, {
    variables: {
      locationId: queryMap.locationId,
      customerId,
      states: queryMap.states,
    },
    onCompleted: () => setLastFetchTime(getCurrentTime()),
    onError: error => showErrorToast(error.message),
    fetchPolicy: 'cache-and-network',
    pollInterval: 180_000,
  })

  const { data: ordersByStatus } = useQuery(GET_ORDERS_BY_STATUS, {
    variables: {
      locationId: queryMap.locationId,
      customerId,
      states: queryMap.states,
    },
    onCompleted: () => setLastFetchTime(getCurrentTime()),
    onError: error => showErrorToast(error.message),
    fetchPolicy: 'cache-and-network',
    pollInterval: 180_000,
  })

  const { data: fillsByStatus = { getFillsByStatus: null } } = useQuery(GET_FILLS_BY_STATUS, {
    variables: {
      locationId: queryMap.locationId,
      customerId,
      states: queryMap.states,
    },
    onCompleted: () => setLastFetchTime(getCurrentTime()),
    onError: error => showErrorToast(error.message),
    fetchPolicy: 'cache-and-network',
    pollInterval: 30_000,
  })

  const { data: triages } = useQuery(GET_TRIAGES, {
    variables: {
      locationId: queryMap.locationId,
      customerId,
      states: queryMap.states,
    },
    onCompleted: () => setLastFetchTime(getCurrentTime()),
    onError: error => showErrorToast(error.message),
    fetchPolicy: 'cache-and-network',
    pollInterval: 180_000,
  })

  const { data: transfers } = useQuery(GET_TRANSFERS, {
    variables: {
      locationId: queryMap.locationId,
      customerId,
    },
    onCompleted: () => setLastFetchTime(getCurrentTime()),
    onError: error => showErrorToast(error.message),
    fetchPolicy: 'cache-and-network',
    pollInterval: 180_000,
  })

  return (
    <QueuesSummary
      lastFetchTime={lastFetchTime}
      fillsByStatus={fillsByStatus.getFillsByStatus}
      triage={
        triages?.getTriages
          ? { total: triages.getTriages.inTriage, between24And48Hours: null, greaterThan48Hours: null }
          : null
      }
      packing={
        ordersByStatus?.getOrdersByStatus
          ? { total: ordersByStatus.getOrdersByStatus.packing, between24And48Hours: null, greaterThan48Hours: null }
          : null
      }
      otcOnly={
        otcOnlyOrders?.getOtcOnlyOrders
          ? { total: otcOnlyOrders.getOtcOnlyOrders.Packing, between24And48Hours: null, greaterThan48Hours: null }
          : null
      }
      transfers={
        transfers?.getTransfers
          ? {
              FaxFailed: transfers.getTransfers.FaxFailed,
              ToTransfer: transfers.getTransfers.ToTransfer,
              NeedsVerification: transfers.getTransfers.NeedsVerification,
            }
          : null
      }
    />
  )
}

export default StatsSection
