import { useCallback } from 'react'
import { Link, useMutation } from '@truepill/tpos-react-router'
import {
  AdjudicationTriageReasons,
  FillTriageReasons,
  PV2TriageReasons,
  OrderTriageMessages,
  PV1TriageReasons,
} from '@truepill/tpos-types'
import type { EntityId } from '@truepill/tpos-types'
import { FilledHeadingStyle, NoResultsEntry } from 'components/PageStructure'
import TriageResolveButton from 'components/TriageOrResolveTriageButton'
import { RUN_THROUGH_CLAIM_JUMPER } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useGetNextTriage from 'hooks/useGetNextTriage'
import moment from 'moment'
import { usePlusClient } from 'providers/VisionRouter'
import { goToFulfillmentOrder } from 'routes'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { capsuleDarkRedColor, contrastBackgroundColor, contrastColor } from 'styles/styleVariables'
import type { Order, Fill, OrderTriage, RXFillRequest, CopayRequest, CopayRequestFill, CopayTriage } from 'types'
import {
  checkIfProvided,
  firstLetterUpper,
  isOrder,
  isOrderTriage,
  removeCamelCase,
  TriageReasonMap,
  CopayTriageReasonMap,
} from 'utils'
import { formatDate } from 'utils/dates'
import ActionButton from './ActionButton'
import { ResolveLink } from './ReviewDuplicatePrescription'
import UserInitialsBadge from './UserInitialsBadge'

const TRIAGE_REASONS = {
  clinical: ['DDI', 'DrugAllergy', 'DrugDisease', 'Contraindication', 'DUR'],
  dataEntry: [
    'PatientNameDOBMismatch',
    'IncorrectSig',
    'IncorrectDaySupply',
    'IncorrectMD',
    'IncorrectDrug',
    'IncorrectPatient',
    'IncorrectQuantity',
    'IncorrectPayerOrPaymentMethod',
    'Other',
    'OffFormularyPrescribedNDC',
    'ControlledRxUtilizationHold',
    'WeatherDelay',
  ],
  adjudication: Object.keys(AdjudicationTriageReasons),
  inventory: Object.keys(FillTriageReasons),
  PV2Exceptions: Object.keys(PV2TriageReasons),
}

function findTriageType(triageReasons: Record<string, string[]>, reason: string) {
  const newArray: string[] = []

  const triageReasonsArray = Object.entries(triageReasons)

  triageReasonsArray.forEach(object => {
    if (object[1].includes(reason)) {
      newArray.push(object[0])
    }
  })
  return newArray
}

type TriageIssuesProps = {
  item: Order | CopayRequest
  fill?: Fill
  itemFill?: RXFillRequest | CopayRequestFill
}

const TriageIssues = ({ item, fill, itemFill }: TriageIssuesProps): JSX.Element => {
  const triages = item?.triages

  if (!triages) {
    return <></>
  }

  return (
    <TriageIssueContainer id="TriageIssueContainer" data-testid="TriageIssueContainer">
      <h2>Triage issues</h2>
      <TriageIssueTable item={item} fill={fill} itemFill={itemFill} />
    </TriageIssueContainer>
  )
}

const TriageIssueTable = ({ item, fill, itemFill }: TriageIssuesProps): JSX.Element => {
  // For copay requests only show triages for a given fill if we are on a fill page (maybe orders also?)
  const triages = isOrder(item)
    ? item?.triages
    : item?.triages?.filter((triage: CopayTriage) => !fill || !triage.fillId || triage.fillId === fill?._id)

  return (
    <TriageContainer>
      <TitleRow />
      {triages &&
        triages.map((triage, i) => (
          <TriageEntry
            key={i}
            triage={triage}
            triages={triages}
            item={item}
            fill={fill}
            itemFill={itemFill}
            triageNumber={i}
          />
        ))}
      {triages?.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
    </TriageContainer>
  )
}

export const TitleRow = (): JSX.Element => {
  return (
    <TitleTriageIssueRow>
      <TriageNumber>No.</TriageNumber>
      <TriageType>Type</TriageType>
      <TriageReason>Reason</TriageReason>
      <FlaggedBy>Flagged by</FlaggedBy>
      <TriageMessage>Message</TriageMessage>
      <TriageActions>Actions</TriageActions>
    </TitleTriageIssueRow>
  )
}

const useOnTriageResolved = (item: Order | CopayRequest, fill: Fill, triageId: string) => {
  const {
    routeTo,
    currentLocation: { queryMap },
  } = usePlusClient()
  const showErrorToast = useErrorToast()

  const { getNextOrder: getNextOrderTriage } = useGetNextTriage()

  const [runThroughClaimJumperMutation] = useMutation<
    {
      runThroughClaimJumper: { _id: string }
    },
    {
      orderId: string
    }
  >(RUN_THROUGH_CLAIM_JUMPER, {
    onError(err) {
      showErrorToast(`Failed to run order through claim jumper: ${err.message.replace('GraphQL error:', '')}`)
    },
  })

  const orderHasOpenTriage = (triages: OrderTriage[], triageId: string) =>
    triages.some(t => t._id !== triageId && !t.endDate)

  return useCallback(() => {
    if (isOrder(item)) {
      const orderStillHasOpenTriage = orderHasOpenTriage(item.triages, triageId)
      // We don't need to wait for the claim jumper response, as we want to just let it pass through and show an error toast if it fails.
      if (!orderStillHasOpenTriage) {
        runThroughClaimJumperMutation({ variables: { orderId: item._id } })
      }

      if (queryMap.workflow === 'queue') {
        getNextOrderTriage(item, fill, triageId)
      } else if (!orderStillHasOpenTriage) {
        routeTo
          .fulfillmentQueue(FulfillmentQueueName.Triage, {
            searchMap: {
              ...queryMap,
            },
          })
          .now()
      }
    }
  }, [item, triageId, queryMap, runThroughClaimJumperMutation, getNextOrderTriage, fill, routeTo])
}

type TriageEntryProps = {
  triage: OrderTriage | CopayTriage
  item: Order | CopayRequest
  triageNumber: number
  fill?: Fill
  itemFill?: RXFillRequest | CopayRequestFill
  triages: (OrderTriage | CopayTriage)[]
  useCopayPaymentValidation?: boolean
}

enum CannotResolveMessage {
  NoPermission = 'No permission',
  NeedToNavigateToFill = 'Can only resolve from fill',
  CantSolveInThisFill = 'Cannot resolve in this fill',
}

const ONLY_RESOLVE_FROM_FILL: string[] = [
  'OffFormularyPrescribedNDC',
  'PreCheck',
  'ControlledRxUtilizationHold',
  'FormularyCheckFailed',
  'OtherConsigned',
]

// ts will infer using a type guard otherwise it wont
const hasPrescriptionId = <T,>(o: { prescriptionId?: string } & T): o is T & { prescriptionId: string } => {
  return 'prescriptionId' in o && !!o.prescriptionId
}

const useShouldShowResolveButton = (
  triage: OrderTriage | CopayTriage,
  triageType: string,
  item: Order | CopayRequest,
  itemFill?: RXFillRequest | CopayRequestFill,
): {
  showButton: boolean
  hideButtonMessage?: CannotResolveMessage
  warningMessage?: string
} => {
  const {
    tokenContext: { isPharmacist, isLeadPharmacist, isAdmin },
  } = usePlusClient()

  const hideResolveButton = !isPharmacist() && !isLeadPharmacist() && triageType === 'clinical'
  if (hideResolveButton) {
    return {
      showButton: false,
      hideButtonMessage: CannotResolveMessage.NoPermission,
    }
  }

  if (triage.reason.toString() === PV1TriageReasons.PatientOutreachRequired) {
    return {
      showButton: true,
    }
  }

  const isPreCheckTriage = triage.reason.toString() === 'PreCheck'
  const triageRxFillRequestId = isOrderTriage(triage) ? triage.rxFillRequestId : undefined

  // We want to let admins solve pre-check triages that do not have a correct rxFillRequest associated
  // in the order. This is an inconsistency in data, so we allow them to resolve it but show them a warning
  const rxFillRequestAssociatedToPreCheckTriageDoesNotExist =
    isOrder(item) &&
    triageRxFillRequestId &&
    isPreCheckTriage &&
    isAdmin() &&
    !itemFill &&
    !item.rxFillRequests.some(fillRequest => fillRequest._id === triageRxFillRequestId)

  if (rxFillRequestAssociatedToPreCheckTriageDoesNotExist) {
    return {
      showButton: true,
      warningMessage: 'This pre-check is not associated to any fill. Are you sure you want to remove it?',
    }
  }

  const shouldResolveFromFill = ONLY_RESOLVE_FROM_FILL.includes(triage.reason)
  const areDifferentFillRequestIds = triageRxFillRequestId && itemFill && triageRxFillRequestId !== itemFill?._id

  // Pre-check and OffFormularyPrescribedNDC triages should be solved inside a fill, since the user needs
  // to use it's info. Makes no sense to solve it from the order page
  const isShownInTheOrder = isOrder(item) && !itemFill && shouldResolveFromFill

  if (isShownInTheOrder) {
    return {
      showButton: false,
      hideButtonMessage: CannotResolveMessage.NeedToNavigateToFill,
    }
  }

  // Since we are showing a specific fill, we only want to show preChecks and OffFormularyPrescribedNDC for
  // that specific fill. If there's no rxFillRequestId in the triage, we assume
  // that it's an order-wide triage that we need to always allow

  if (areDifferentFillRequestIds && shouldResolveFromFill) {
    return {
      showButton: false,
      hideButtonMessage: CannotResolveMessage.CantSolveInThisFill,
    }
  }

  return { showButton: true }
}

export const TriageEntryGeneric = ({
  isAlreadyResolved = false,
  triageReason,
  triageNumber,
  triageType,
  triageEndDate,
  triageMessage,
  userPlaced,
  userRemoved,
  onResolveClick,
}: {
  showResolveButton?: boolean
  isAlreadyResolved: boolean
  triageReason: string
  triageNumber: string
  triageType?: string
  triageMessage?: string
  triageEndDate?: Date
  userPlaced?: { _id: EntityId; firstName?: string; lastName?: string }
  userRemoved?: { _id: EntityId; firstName?: string; lastName?: string }
  onResolveClick?: () => void
}) => {
  const userPlacedHasName = userPlaced?.firstName && userPlaced?.lastName
  const userRemovedHasName = userRemoved?.firstName && userRemoved?.lastName

  return (
    <TriageIssueRow>
      <TriageNumber data-testid="triage-number-value" isAlreadyResolved={isAlreadyResolved}>
        {triageNumber}
      </TriageNumber>
      <TriageType data-testid="triage-type-value" isAlreadyResolved={isAlreadyResolved}>
        {triageType ? firstLetterUpper(removeCamelCase(triageType)) : 'Other'}
      </TriageType>
      <TriageReason data-testid="triage-reason-value" isAlreadyResolved={isAlreadyResolved}>
        {removeCamelCase(triageReason)}
      </TriageReason>
      <FlaggedBy data-testid="triage-flagged-by-value">
        {userPlaced ? (
          userPlacedHasName ? (
            <BadgeContainer>
              <UserInitialsBadge user={userPlaced as { _id: string; firstName: string; lastName: string }} />
            </BadgeContainer>
          ) : (
            <TriageMessage>System</TriageMessage>
          )
        ) : null}
      </FlaggedBy>
      <TriageMessage data-testid="triage-message-value" isAlreadyResolved={isAlreadyResolved}>
        {triageMessage}
      </TriageMessage>
      <TriageActions>
        {!triageEndDate ? (
          <ActionButton onClick={onResolveClick} data-testid="loading" label="Resolve" />
        ) : (
          <UserActions>
            {userRemoved
              ? userRemovedHasName && (
                  <BadgeContainer spacing>
                    <UserInitialsBadge user={userRemoved as { _id: string; firstName: string; lastName: string }} />
                  </BadgeContainer>
                )
              : null}{' '}
            {formatDate(triageEndDate, 'MM/DD/YYYY hh:mmA')}
          </UserActions>
        )}
      </TriageActions>
    </TriageIssueRow>
  )
}

const isDuplicateRxTriage = (triage: CopayTriage | OrderTriage | undefined) =>
  isOrderTriage(triage) && triage.inTriageUserMessage?.includes(OrderTriageMessages.DUPLICATE_PRESCRIPTION)

const TriageEntry = ({ triage, triageNumber, item, fill, itemFill, triages }: TriageEntryProps): JSX.Element | null => {
  const userPlaced = isOrderTriage(triage) ? triage.userPlaced : undefined
  const userRemoved = isOrderTriage(triage) ? triage.userRemoved : undefined
  const triageReason = triage.reason

  const prescriptionFill = isOrder(item)
    ? itemFill?.prescription.fills.find(fill => fill._id === itemFill.fillId)
    : undefined
  const claimSumaryCopay = prescriptionFill?.claimSummary?.copay

  const rxFillRequest = isOrder(item)
    ? item.rxFillRequests.find(rxFillRequest => rxFillRequest.fillId === itemFill?.fillId)
    : undefined
  const fillCopay = isOrder(item) ? rxFillRequest?.copayPayment?.transactionAmount : undefined

  const copayAmountChanged =
    triage.reason === PV1TriageReasons.PatientOutreachRequired &&
    (claimSumaryCopay !== fillCopay || (!claimSumaryCopay && !fillCopay))

  const triageType = findTriageType(TRIAGE_REASONS, triageReason)[0] as string

  const onTriageResolved = useOnTriageResolved(item, fill as Fill, triage._id as string)

  const { showButton, hideButtonMessage, warningMessage } = useShouldShowResolveButton(
    triage,
    triageType,
    item,
    itemFill,
  )
  const triageMessageRaw = isOrderTriage(triage) ? triage.inTriageUserMessage : triage.message
  const triageMessage = checkIfProvided(triageMessageRaw)

  const isAlreadyResolved = !!triage.endDate
  const userPlacedHasName = userPlaced?.firstName && userPlaced?.lastName
  const userRemovedHasName = userRemoved?.firstName && userRemoved?.lastName
  const triageReasonMap = isOrderTriage(triage) ? TriageReasonMap : CopayTriageReasonMap

  return (
    <TriageIssueRow>
      <TriageNumber data-testid="triage-number-value" isAlreadyResolved={isAlreadyResolved}>
        {triageNumber}
      </TriageNumber>
      <TriageType data-testid="triage-type-value" isAlreadyResolved={isAlreadyResolved}>
        {triageType ? firstLetterUpper(removeCamelCase(triageType)) : 'Other'}
      </TriageType>
      <TriageReason data-testid="triage-reason-value" isAlreadyResolved={isAlreadyResolved}>
        {triageReasonMap[triageReason]}
      </TriageReason>
      <FlaggedBy data-testid="triage-flagged-by-value">
        {userPlaced ? (
          userPlacedHasName ? (
            <BadgeContainer>
              <UserInitialsBadge user={userPlaced} />
            </BadgeContainer>
          ) : (
            <TriageMessage>System</TriageMessage>
          )
        ) : null}
      </FlaggedBy>
      {/* Todo: Should we delete the isDuplicateRxTriage validator in the future? since the past duplicate triaged
      orders might have been already resolved by then // JR-14677 */}
      {(!isOrderTriage(triage) || !triage.duplicateOrderId) && isDuplicateRxTriage(triage) ? (
        <TriageMessage data-testid="triage-message-value" isAlreadyResolved={isAlreadyResolved}>
          {triageMessage}
          {hasPrescriptionId(triage) && isOrderTriage(triage) && itemFill?.prescriptionId && (
            <ResolveLink
              triage={triage}
              orderId={item._id}
              mainPrescriptionId={itemFill.prescriptionId}
              duplicatePrescriptionIds={
                triages
                  .filter(t => isDuplicateRxTriage(t) && hasPrescriptionId(t))
                  .map(t => t.prescriptionId) as string[]
              }
            />
          )}
        </TriageMessage>
      ) : !isOrderTriage(triage) || !triage.duplicateOrderId ? (
        <TriageMessage data-testid="triage-message-value" isAlreadyResolved={isAlreadyResolved}>
          {triageMessage}
        </TriageMessage>
      ) : (
        <TriageMessage isAlreadyResolved={isAlreadyResolved}>
          {triageMessage}
          <StyledLink
            target="_blank"
            to={goToFulfillmentOrder({ fulfillmentQueueName: 'all', orderId: triage.duplicateOrderId })}
          >
            Original Order
          </StyledLink>
        </TriageMessage>
      )}
      <TriageActions>
        {!triage.endDate ? (
          !showButton ? (
            <TriageMessage isAlreadyResolved={isAlreadyResolved}>{hideButtonMessage}</TriageMessage>
          ) : (
            <TriageResolveButton
              item={item}
              onTriageResolved={onTriageResolved}
              triageId={triage._id}
              warningMessage={warningMessage}
              copayAmountChanged={copayAmountChanged}
            />
          )
        ) : (
          <UserActions>
            {userRemoved
              ? userRemovedHasName && (
                  <BadgeContainer spacing>
                    <UserInitialsBadge user={userRemoved} />
                  </BadgeContainer>
                )
              : null}{' '}
            {moment(parseInt(triage.endDate)).format('MM/DD/YYYY hh:mmA')}
          </UserActions>
        )}
      </TriageActions>
    </TriageIssueRow>
  )
}

const StyledLink = styled(Link)<{
  duplicateRx?: boolean
}>`
  margin: 0 1rem;
  text-decoration: underline;
  color: ${({ duplicateRx }) => duplicateRx && capsuleDarkRedColor};
  :visited {
    color: ${({ duplicateRx }) => duplicateRx && capsuleDarkRedColor};
  }
`

export const TriageIssueContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  h2 {
    width: 100%;
    margin-bottom: 0.625rem;
  }
  margin-bottom: 1.25rem;
`

const BadgeContainer = styled.span<{
  spacing?: boolean
}>`
  font-size: 1.25rem;
  ${({ spacing }) => spacing && 'margin-right: 0.625rem'}
`

export const TriageContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  align-items: stretch;
  :last-child {
    border-bottom: 0.25rem solid ${contrastColor};
    border-radius: 0.25rem;
  }
  flex: 1;
`

const TriageIssueRow = styled.ul`
  display: grid;
  grid-column: content;
  grid-template-rows: auto;
  grid-template-columns:
    [triageNumber] minmax(3rem, 0.1fr) [triageType] minmax(6rem, 0.4fr)
    [triageReason] minmax(6rem, 0.5fr) [flaggedBy] minmax(4rem, 8rem)
    [triageMessage] minmax(6rem, 1.2fr) [triageActions] minmax(6rem, 13rem);
  padding-left: 0.625rem;
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
  :not(:first-child) {
    min-height: 40px;
  }
`

const TitleTriageIssueRow = styled(TriageIssueRow)`
  position: sticky;
  grid-template-rows: [content] 1.5rem;
  top: 0rem;
  ${FilledHeadingStyle}
  padding: 0.1rem 0;
  padding-left: 0.625rem;
  font-weight: 500;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  :nth-of-type(2n + 1) {
    background-color: ${contrastColor};
  }
`

const TriageRowCell = styled.li`
  display: flex;
  grid-row: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
  ${EllipsisTruncate};
`

const TrucateTextPosition = css`
  display: block;
`

const TriageNumber = styled(TriageRowCell)<{ isAlreadyResolved?: boolean }>`
  grid-column: triageNumber;
  ${TrucateTextPosition}
  ${props => (props.isAlreadyResolved ? 'text-decoration: line-through;' : '')}
`

const TriageType = styled(TriageRowCell)<{ isAlreadyResolved?: boolean }>`
  grid-column: triageType;
  ${TrucateTextPosition}
  ${props => (props.isAlreadyResolved ? 'text-decoration: line-through;' : '')}
`

const TriageReason = styled(TriageRowCell)<{ isAlreadyResolved?: boolean }>`
  grid-column: triageReason;
  ${TrucateTextPosition}
  ${props => (props.isAlreadyResolved ? 'text-decoration: line-through;' : '')}
`

const FlaggedBy = styled(TriageRowCell)`
  grid-column: flaggedBy;
  ${TrucateTextPosition}
  display: flex;
  justify-content: center;
`

const TriageMessage = styled(TriageRowCell)<{ isAlreadyResolved?: boolean }>`
  grid-column: triageMessage;
  ${TrucateTextPosition}
  white-space: normal;
  padding-top: 6px;
  padding-bottom: 6px;
  ${props => (props.isAlreadyResolved ? 'text-decoration: line-through;' : '')}
`

const TriageActions = styled(TriageRowCell)`
  grid-column: triageActions;
  display: flex;
  flex-basis: 100%;
  button {
    margin: 0.125rem;
  }
  ${TrucateTextPosition}
`

const UserActions = styled.p`
  display: flex;
  flex-basis: 100%:
`

export default TriageIssues
