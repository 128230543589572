import { Box, Button, Divider, Text } from '@truepill/react-capsule'
import type { PatientSurveyInput } from '@truepill/tpos-types'
import Highlight from 'components/Highlight'
import styled from 'styled-components'
import { errorDark } from 'styles/styleVariables'

interface DefualtHighlights {
  allergies: string[]
  conditions: string[]
  medications: string[]
}

interface SurveySectionProps {
  totalEdits: number
  onEditHistoryClick: () => void
  defaultHighlights: DefualtHighlights
  survey: PatientSurveyInput
  onAppendHighlight: (type: 'allergies' | 'medications' | 'conditions', highlight: string) => void
  onRemoveHighlight: (type: 'allergies' | 'medications' | 'conditions', highlight: string) => void
}

const SurveySection = ({
  onEditHistoryClick,
  defaultHighlights,
  survey,
  onAppendHighlight,
  onRemoveHighlight,
  totalEdits = 0,
}: SurveySectionProps) => {
  return (
    <Container>
      <Wrapper>
        <Box>
          <Title bold>Patient survey</Title>
          <Text>Highlight words / phrases to create tokens</Text>
        </Box>
        <Box>
          <Title bold>Allergies</Title>
          <Highlight
            key="highlight-text-allergies"
            containerName="highlight-text-allergies"
            defaultHighlights={defaultHighlights?.allergies ?? []}
            text={survey?.allergies ?? ''}
            onHighlight={(highlight: string) => onAppendHighlight('allergies', highlight)}
            onUnhighlight={(unhighlight: string) => onRemoveHighlight('allergies', unhighlight)}
          />
        </Box>
        <Box>
          <Title bold>Medications</Title>
          <Highlight
            key="highlight-text-medications"
            containerName="highlight-text-medications"
            defaultHighlights={defaultHighlights?.medications ?? []}
            text={survey?.medications ?? ''}
            onHighlight={(highlight: string) => onAppendHighlight('medications', highlight)}
            onUnhighlight={(unhighlight: string) => onRemoveHighlight('medications', unhighlight)}
          />
        </Box>
        <Box>
          <Title bold>Conditions</Title>
          <Highlight
            key="highlight-text-conditions"
            containerName="highlight-text-conditions"
            defaultHighlights={defaultHighlights?.conditions ?? []}
            text={survey?.conditions ?? ''}
            onHighlight={(highlight: string) => onAppendHighlight('conditions', highlight)}
            onUnhighlight={(unhighlight: string) => onRemoveHighlight('conditions', unhighlight)}
          />
        </Box>
        <Divider variant="mid" />
        <OptionsContainer>
          <Button
            css={{ display: 'flex', padding: 0 }}
            size="sm"
            variant="primary-text"
            type="button"
            onClick={onEditHistoryClick}
          >
            Edit history
          </Button>
          {totalEdits > 0 && (
            <NotificationBubble>
              <span>{totalEdits}</span>
            </NotificationBubble>
          )}
        </OptionsContainer>
      </Wrapper>
    </Container>
  )
}

const OptionsContainer = styled(Box)`
  display: flex;
  justifycontent: flex-start;
  alignitems: center;
  gap: 0.25rem;
`

const Title = styled(Text)`
  line-height: 1.5rem;
`

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

const Container = styled(Box)`
  padding: 1rem 1rem 1rem 0;
`

const NotificationBubble = styled.span`
  display: flex;
  align-items: center;
  > span {
    border-radius: 50%;
    background-color: ${errorDark};
    font-size: 0.75rem;
    color: #fff;
    padding: 0.1875rem;
    min-width: 1.1rem;
    min-height: 0.8rem;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    user-select: none;
  }
`

export default SurveySection
