import { useEffect, useState } from 'react'
import { UserRoles } from '@truepill/tpos-types'
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external-link.svg'
import AddressEntry from 'components/AddressEntry'
import HotKeyToolTip from 'components/HotKeyToolTip'
import IconWrapper from 'components/IconWrapper'
import RXScanImage from 'components/RXScanImage'
import {
  ImageFilledHeader,
  ListRowLabel,
  ListRowValue,
  ListRowExtraValue,
  RaisedAnchor,
  RightFilledHeader,
  RXCenterCell,
  RXCenterColumn,
  RXImageCell,
  RXListRow,
  RXRightCell,
  RXRow,
  RXTitleRow,
  StyledRXImageCell,
  ExtraValueEntry,
  AliasMarker,
  DiffTooltip,
} from 'components/RXTable'
import { Box } from 'grommet'
import useEditMode from 'hooks/useEditMode'
import { useFormData } from 'hooks/useFormData'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { usePopup } from 'hooks/usePopup'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import { useClient } from 'providers/LaunchDarklyProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { goToViewRxImagePharmacyPrescription } from 'routes'
import styled from 'styled-components'
import { alertRed, bodyPrimaryColor, contrastColor } from 'styles/styleVariables'
import type { EscriptPatient, Patient, Weight, Prescription } from 'types'
import { PrescriptionOrigin } from 'types'
import {
  checkIfProvided,
  formatPhoneNumber,
  getRxImagePatient,
  convertWeight,
  getRxImagePatientManualEntry,
} from 'utils'
import type { PatientField } from 'utils/compare'
import { isHuman } from 'utils/Patient'
import { buildReviewPatientSectionValues, isFirstFill } from 'utils/rxFields'
import NoEscriptInfo from './NoEscriptInfo'
import PopupIsOpenInfo from './PopupIsOpenInfo'
import ReviewPatientEdit from './ReviewPatientEdit'

const ORIGIN_MAPPING = {
  [PrescriptionOrigin.ELECTRONIC]: 'eRx',
  [PrescriptionOrigin.PHARMACY]: 'Transfer in',
  [PrescriptionOrigin.FAX]: 'Manual intake',
  [PrescriptionOrigin.WRITTEN]: 'Manual intake',
  [PrescriptionOrigin.TELEPHONE]: 'Manual intake',
}

const ReviewPatient = ({
  prescription,
  patient,
  rightHeader,
  rightContent,
  highlightedFields = new Map<PatientField, boolean>(),
}: {
  prescription: Prescription
  patient: Patient
  rightHeader?: JSX.Element
  rightContent?: JSX.Element
  highlightedFields?: Map<PatientField, boolean>
}): JSX.Element => {
  const [editMode] = useEditMode()
  const [showRxImageAsPdf, setShowRxImageAsPdf] = useRxImageIsPic()
  const [noEscript, setNoEscript] = useState(false)
  const [isPopupOpenState, setIsPopupOpenState] = useState(false)
  const [showPopupIsOpenText, setShowPopupIsOpenText] = useState(false)
  const { openPopup, isPopupOpen } = usePopup()

  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()
  const { routeToHash } = usePlusClient()
  const patientFormData = formData.patient as Patient

  const patientWeight = patient.weight?.value ? convertWeight(patient.weight.value as number) : ({} as Weight)

  const customer = prescription.customer
  // Set the customer id for upcoming feature-flag calls
  const { client: ldClient } = useClient()

  useEffect(() => {
    if (customer?.legacyId) {
      ldClient?.identify({ key: customer?.legacyId.toString() })
    }
  }, [ldClient, customer])

  useEffect(() => {
    if (!patientFormData?._id) {
      updateFormData({ patient: { $set: patient } })
    }
  }, [patient, patientFormData, updateFormData])

  // If the prescription's origin is “electronic”, then display the transcribed Rx image by default
  useEffect(() => {
    const defaultToTranscribedRx = [PrescriptionOrigin.ELECTRONIC, PrescriptionOrigin.TELEPHONE].includes(
      prescription?.origin,
    )
    setShowRxImageAsPdf(!defaultToTranscribedRx)
    setShowPopupIsOpenText(!defaultToTranscribedRx)
  }, [prescription?.rxNumber, prescription?.origin, setShowRxImageAsPdf, setShowPopupIsOpenText])

  useEffect(() => {
    const isOpen = isPopupOpen()
    setIsPopupOpenState(isOpen)
    if (isOpen) {
      setShowRxImageAsPdf(false)
    }
  }, [isPopupOpen, setIsPopupOpenState, setShowRxImageAsPdf, setShowPopupIsOpenText, prescription])

  useHotKey('x', HotKeyLevel.normal, () => {
    if (
      !prescription?.escript &&
      !prescription?.directTransfer &&
      !(prescription.origin === PrescriptionOrigin.TELEPHONE)
    ) {
      setNoEscript(!noEscript)
    } else if (isPopupOpenState) {
      setShowPopupIsOpenText(!showPopupIsOpenText)
      setShowRxImageAsPdf(true)
    } else {
      setShowRxImageAsPdf(!showRxImageAsPdf)
    }
  })

  useHotKey('1', HotKeyLevel.normal, () => {
    routeToHash(`ReviewPatient`)
  })

  const {
    tokenContext: { hasRole },
  } = usePlusClient()

  if (editMode && !hasRole(UserRoles.CustomerSupport)) {
    return (
      <ReviewPatientEdit
        prescription={prescription}
        patient={patient}
        rightHeader={rightHeader}
        rightContent={rightContent}
        highlightedFields={highlightedFields}
      />
    )
  }

  const escriptPatient = prescription?.escript?.patient as EscriptPatient
  const directTransferPatient = prescription?.directTransfer?.patient
  let rxImagePatient
  if (prescription.origin === PrescriptionOrigin.TELEPHONE) {
    rxImagePatient = getRxImagePatientManualEntry(prescription?.patient)
  } else {
    rxImagePatient = getRxImagePatient(escriptPatient, directTransferPatient)
  }

  if (!patient || !patientFormData?._id) {
    return <></>
  }

  const secondaryInfo = patient.secondaryInfo

  const firstFill = isFirstFill(prescription)
  const { rxPatientData, reviewPatientData } = buildReviewPatientSectionValues(
    rxImagePatient,
    patient,
    patientFormData,
    firstFill,
  )

  return (
    <Box id="ReviewPatientBox" direction="column" data-testid="ReviewPatient">
      <RXTitleRow data-testid="headers">
        <RaisedAnchor id="ReviewPatient" />
        <StyledRXImageCell noBorder>
          <ImageFilledHeader>
            Rx image {prescription.origin in ORIGIN_MAPPING ? `(${ORIGIN_MAPPING[prescription.origin]})` : ''}
            <PrescriptionImageButton
              onClick={() => {
                openPopup(goToViewRxImagePharmacyPrescription({ prescriptionId: prescription._id }))
                setIsPopupOpenState(true)
                setShowRxImageAsPdf(false)
                setShowPopupIsOpenText(prescription?.origin !== PrescriptionOrigin.ELECTRONIC)
                localStorage.setItem('isPopupOpen', 'true')
              }}
            >
              <IconWrapper>
                <ExternalLinkIcon />
              </IconWrapper>
            </PrescriptionImageButton>
          </ImageFilledHeader>
        </StyledRXImageCell>
        <RXCenterColumn>
          <RightFilledHeader>
            Review patient
            <HotKeyToolTip label="1" position="right" offsetLeft={1} offsetTop={0} />
          </RightFilledHeader>
        </RXCenterColumn>
        {rightHeader && (
          <RXAnnotationsRightCell>
            <RightFilledHeader>{rightHeader}</RightFilledHeader>
          </RXAnnotationsRightCell>
        )}
      </RXTitleRow>

      <RXRow data-testid="image">
        {showRxImageAsPdf && !isPopupOpenState && (
          <ScanImageWrapper>
            <RXScanImage prescription={prescription} />
          </ScanImageWrapper>
        )}
        {!(showPopupIsOpenText && isPopupOpenState) && noEscript && (
          <NoEscriptInfo setNoEscript={() => setNoEscript(false)} />
        )}
        {showPopupIsOpenText && isPopupOpenState && <PopupIsOpenInfo />}

        <StyledRXImageCell closeOffTopBorder></StyledRXImageCell>
      </RXRow>

      <Box direction="row">
        <Box basis="2/3" width={{ min: '53rem' }} data-testid="table-body">
          <RXListRowTwoThirds data-test-row="name">
            <RXImageCell>
              <ListRowLabel>Name:</ListRowLabel>
              <ListRowValue data-private>{rxPatientData.name.value}</ListRowValue>
            </RXImageCell>
            <RXCenterCell
              showIcon={secondaryInfo?.names?.length > 0}
              className={highlightedFields.get('name') ? 'highlighted' : ''}
            >
              <ListRowLabel>Name:</ListRowLabel>
              <ListRowValue data-private color={reviewPatientData.name.highlightValue ? alertRed : bodyPrimaryColor}>
                {reviewPatientData.name.highlightValue && <DiffTooltip message={reviewPatientData.name.message} />}
                {reviewPatientData.name.value}
              </ListRowValue>
              {secondaryInfo?.names?.length > 0 && (
                <ListRowExtraValue>
                  {secondaryInfo.names.map((name, index) => (
                    <ExtraValueEntry key={index} data-private>
                      {name.firstName} {name.lastName} {<AliasMarker>(alias)</AliasMarker>}
                    </ExtraValueEntry>
                  ))}
                </ListRowExtraValue>
              )}
            </RXCenterCell>
          </RXListRowTwoThirds>
          {!isHuman(patient) && (
            <>
              <RXListRowTwoThirds data-test-row="species">
                <RXImageCell>
                  <ListRowLabel>Species:</ListRowLabel>
                  <ListRowValue>{patient?.species}</ListRowValue>
                </RXImageCell>
                <RXCenterCell>
                  <ListRowLabel>Species:</ListRowLabel>
                  <ListRowValue>{patient?.species}</ListRowValue>
                </RXCenterCell>
              </RXListRowTwoThirds>
              <RXListRowTwoThirds data-test-row="guardian">
                <RXImageCell>
                  <ListRowLabel>Guardian:</ListRowLabel>
                  <ListRowValue>{patient?.guardian}</ListRowValue>
                </RXImageCell>
                <RXCenterCell>
                  <ListRowLabel>Guardian:</ListRowLabel>
                  <ListRowValue>{patient?.guardian}</ListRowValue>
                </RXCenterCell>
              </RXListRowTwoThirds>
            </>
          )}
          <RXListRowTwoThirds data-test-row="dob">
            <RXImageCell>
              <ListRowLabel>DOB:</ListRowLabel>
              <ListRowValue data-private>{rxPatientData.dob.value}</ListRowValue>
            </RXImageCell>
            <RXCenterCell className={highlightedFields.get('dob') ? 'highlighted' : ''}>
              <ListRowLabel>DOB:</ListRowLabel>
              <ListRowValue data-private color={reviewPatientData.dob.highlightValue ? alertRed : bodyPrimaryColor}>
                {reviewPatientData.dob.highlightValue && <DiffTooltip message={reviewPatientData.dob.message} />}
                {reviewPatientData.dob.value}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          {patient.weight?.value && (
            <RXListRowTwoThirds data-test-row="weight">
              <RXImageCell>
                <ListRowLabel>Weight:</ListRowLabel>
                <ListRowValue data-private>{`${patientWeight.kg} kg / ${patientWeight.lb} lb`}</ListRowValue>
              </RXImageCell>
              <RXCenterCell>
                <ListRowLabel>Weight:</ListRowLabel>
                <ListRowValue data-private>{`${patientWeight.kg} kg / ${patientWeight.lb} lb`}</ListRowValue>
              </RXCenterCell>
            </RXListRowTwoThirds>
          )}
          <RXListRowTwoThirds data-test-row="gender">
            <RXImageCell>
              <ListRowLabel>Gender:</ListRowLabel>
              <ListRowValue data-private>{rxPatientData.gender.value}</ListRowValue>
            </RXImageCell>
            <RXCenterCell className={highlightedFields.get('gender') ? 'highlighted' : ''}>
              <ListRowLabel>Gender:</ListRowLabel>
              <ListRowValue data-private color={reviewPatientData.gender.highlightValue ? alertRed : bodyPrimaryColor}>
                {reviewPatientData.gender.highlightValue && <DiffTooltip message={reviewPatientData.gender.message} />}
                {reviewPatientData.gender.value}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="address">
            <RXImageCell>
              <ListRowLabel>Address:</ListRowLabel>
              <ListRowValue>
                <AddressEntry data-private address={rxImagePatient?.address} />
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell
              showIcon={secondaryInfo?.addresses?.length > 0}
              className={highlightedFields.get('address') ? 'highlighted' : ''}
            >
              <ListRowLabel>Address:</ListRowLabel>
              <ListRowValue color={reviewPatientData.address.highlightValue ? alertRed : bodyPrimaryColor}>
                {reviewPatientData.address.highlightValue && (
                  <DiffTooltip message={reviewPatientData.address.message} />
                )}
                <AddressEntry data-private address={patientFormData.address?.home} />
              </ListRowValue>
              {secondaryInfo?.addresses?.length > 0 && (
                <ListRowExtraValue>
                  {secondaryInfo.addresses.map((address, index) => (
                    <ExtraValueEntry key={index}>
                      <AddressEntry data-private address={address} />
                    </ExtraValueEntry>
                  ))}
                </ListRowExtraValue>
              )}
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="phone">
            <RXImageCell>
              <ListRowLabel>Phone:</ListRowLabel>
              <ListRowValue data-private>{rxPatientData.phone.value}</ListRowValue>
            </RXImageCell>
            <RXCenterCell
              showIcon={secondaryInfo?.phoneNumbers?.length > 0}
              className={highlightedFields.get('phone') ? 'highlighted' : ''}
            >
              <ListRowLabel>Phone:</ListRowLabel>
              <ListRowValue data-private color={reviewPatientData.phone.value ? alertRed : bodyPrimaryColor}>
                {reviewPatientData.phone.highlightValue && <DiffTooltip message={reviewPatientData.phone.message} />}
                {reviewPatientData.phone.value}
              </ListRowValue>
              {secondaryInfo?.phoneNumbers?.length > 0 && (
                <ListRowExtraValue>
                  {secondaryInfo.phoneNumbers.map((phone, index) => (
                    <ExtraValueEntry key={index} data-private>
                      {checkIfProvided(formatPhoneNumber(phone))}
                    </ExtraValueEntry>
                  ))}
                </ListRowExtraValue>
              )}
            </RXCenterCell>
          </RXListRowTwoThirds>
        </Box>

        {rightContent && (
          <Box basis="1/3" overflow="auto" data-testid="fillrequest-container">
            {rightContent}
          </Box>
        )}
      </Box>
    </Box>
  )
}

const RXListRowTwoThirds = styled(RXListRow)`
  grid-template-columns: [image] minmax(26.5rem, 1fr) [center] minmax(26.5rem, 1fr);
`

const RXAnnotationsRightCell = styled(RXRightCell)`
  flex-wrap: wrap;
  overflow-y: visible;
  position: relative;
  height: 0;
  padding-left: 0;
  background-color: white;
`

const ScanImageWrapper = styled.div`
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  grid-row: 1 / span 1;
  grid-column: image;
  width: 100%;
  display: grid;
  justify-content: center;
`

const PrescriptionImageButton = styled.button`
  background: ${contrastColor};
  border: 0;
  box-shadow: none;
  border-radius: 0px;
`
export default ReviewPatient
