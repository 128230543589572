import { Button, Spacer } from '@truepill/react-capsule'
import { useOrderLock, useCopayLock } from 'hooks'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { AlertCircle } from 'react-feather'
import { isOrder, isOrderReadOnly } from 'utils'
import { TriageModal } from '../../components'
import type { TriageButtonProps } from './types'

export const TriageCapsuleButton = ({
  onClick,
  item,
  fillId,
  disabled,
  onDismissModal,
}: TriageButtonProps): JSX.Element => {
  const showSuccessToast = useSuccessToast()
  const { showModal } = useModalContext()
  const isOrderItem = isOrder(item)
  const { orderEditable } = useOrderLock(item._id, !isOrderItem)
  const isOrderEditable = isOrderItem && orderEditable && !isOrderReadOnly(item)
  const { copayEditable } = useCopayLock(item._id, isOrderItem)

  return (
    <Button
      size="sm"
      variant="primary-outline"
      disabled={(isOrderItem && !isOrderEditable) || (!isOrderItem && !copayEditable) || disabled}
      style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}
      data-testid="Triage"
      aria-keyshortcuts={'T'}
      onClick={() =>
        showModal(() => (
          <TriageModal
            item={item}
            fillId={fillId}
            confirmationCallback={() => {
              showSuccessToast(`Order ${item._id} with fill ${fillId} - moved to triage`)
              onClick?.()
            }}
            onDismiss={onDismissModal}
          />
        ))
      }
    >
      <AlertCircle aria-hidden />
      <Spacer size="xs" />
      Triage
    </Button>
  )
}
