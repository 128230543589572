import { UserRoles, FillStatus } from '@truepill/tpos-types'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg'
import { ReactComponent as ReverseIcon } from 'assets/icons/reverse.svg'
import ActionButton from 'components/ActionButton'
import AuthLimited from 'components/AuthLimited'
import IconWrapper from 'components/IconWrapper'
import { ActionButtonContainer } from 'components/PageStructure'
import useOrderLock from 'hooks/useOrderLock'
import PrintModal from 'modals/PrintModal'
import ReverseModal from 'modals/ReverseModal'
import SendBackAndTriageModal from 'modals/SendBackAndTriageModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Order } from 'types'
import { isOrderReadOnly } from 'utils'
import { WrapButton } from '../fill/ActionButtons'

type ActionButtonsProps = { order: Order; fill: Fill }

const ActionButtons = ({ fill, order }: ActionButtonsProps): JSX.Element => {
  const { showModal } = useModalContext()
  const { orderEditable } = useOrderLock(order._id)
  const isOrderDisabled = isOrderReadOnly(order)
  const editable = orderEditable && !isOrderDisabled
  const { Pharmacist, LeadPharmacist, LeadCustomerSupport, Admin, CustomerSupport, Technician } = UserRoles
  const customerId = order.rxFillRequests[0]?.prescription?.customer?.legacyId

  const showPrintModal = () => (
    <PrintModal
      fills={[{ fillId: fill._id, orderId: order._id }]}
      isReprint
      customerId={customerId}
      title="Reprint label"
    />
  )

  return (
    <ActionButtonContainer>
      <AuthLimited roles={[LeadPharmacist]}>
        <ActionButton
          disabled={!editable}
          icon={
            <IconWrapper>
              <CancelIcon fill={bodyPrimaryColor} />
            </IconWrapper>
          }
          label={'Send back'}
          onClick={() => {
            showModal(() => <SendBackAndTriageModal fillId={fill._id} orderId={order._id} />)
          }}
        />
      </AuthLimited>
      <AuthLimited roles={[Pharmacist, LeadPharmacist, LeadCustomerSupport, Admin, CustomerSupport, Technician]}>
        <WrapButton variant="primary-outline" onClick={() => showModal(showPrintModal)} disabled={!editable}>
          <IconWrapper>
            <PrintIcon />
          </IconWrapper>
          Reprint
        </WrapButton>
      </AuthLimited>
      {fill.status === FillStatus.Complete && (
        <AuthLimited roles={[LeadPharmacist, Pharmacist, LeadCustomerSupport, CustomerSupport]}>
          <ActionButton
            disabled={!editable}
            icon={
              <IconWrapper>
                <ReverseIcon fill={bodyPrimaryColor} />
              </IconWrapper>
            }
            label={'Reverse fill'}
            onClick={() => {
              showModal(() => <ReverseModal order={order} fill={fill} />)
            }}
          />
        </AuthLimited>
      )}
    </ActionButtonContainer>
  )
}

export default ActionButtons
