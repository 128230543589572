import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { useOrderLock, useCopayLock } from 'hooks'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'
import { isOrder, isOrderReadOnly } from 'utils'
import { TriageModal } from '../../components'
import type { TriageButtonProps } from './types'

export const TriageButton = ({ onClick, item, fillId, disabled, onDismissModal }: TriageButtonProps): JSX.Element => {
  const showSuccessToast = useSuccessToast()
  const { showModal } = useModalContext()
  const isOrderItem = isOrder(item)
  const { orderEditable } = useOrderLock(item._id, !isOrderItem)
  const isOrderEditable = isOrderItem && orderEditable && !isOrderReadOnly(item)
  const { copayEditable } = useCopayLock(item._id, isOrderItem)

  return (
    <ActionButton
      data-testid="Triage"
      disabled={(isOrderItem && !isOrderEditable) || (!isOrderItem && !copayEditable) || disabled}
      icon={
        <IconWrapper>
          <DangerIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label="Triage"
      onClick={() =>
        showModal(() => (
          <TriageModal
            item={item}
            fillId={fillId}
            confirmationCallback={() => {
              const fillMessage = fillId ? ` with fill ${fillId}` : ''
              const itemType = isOrder(item) ? 'Order' : 'Copay'
              showSuccessToast(`${itemType} ${item._id}${fillMessage} - moved to triage`)
              onClick?.()
            }}
            onDismiss={onDismissModal}
          />
        ))
      }
    />
  )
}
