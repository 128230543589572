import { useState } from 'react'
import { Tooltip } from '@truepill/react-capsule'
import { LaunchDarkly } from '@truepill/tpos-types'
import { ReactComponent as CollapseIcon } from 'assets/icons/collapse.svg'
import { ReactComponent as ExpandIcon } from 'assets/icons/expand.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import IconWrapper from 'components/IconWrapper'
import { FilledHeader } from 'components/PageStructure'
import Select from 'components/Select'
import { TextInput, TextArea } from 'grommet'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import { useFlag } from 'providers/LaunchDarklyProvider'
import styled, { css } from 'styled-components'
import {
  contrastBackgroundColor,
  alertRed,
  primaryBackgroundColor,
  borderColor,
  bodyPrimaryColor,
} from 'styles/styleVariables'
import type { ChildProps, OptChildProps } from 'types'

export const RXBorderStyle = `0.125rem solid ${borderColor}`

export const RXTable = styled.ul``
RXTable.displayName = 'RXTable'

export const TableContainer = styled.div`
  padding-bottom: 3rem;
`
TableContainer.displayName = 'TableContainer'

export const ThreeColumnDivision = css`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns:
    [image] minmax(26.5rem, 1fr) [center] minmax(26.5rem, 1fr)
    [right] minmax(26.5rem, 1fr);
`

export const TwoColumnDivision = css`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [center] 1fr [right] 1fr;
`

export const RXSegment = styled.ul`
  grid-template-rows: [title] auto [content] auto;
`
RXSegment.displayName = 'RXSegment'

export const CloseOffTopBorder = css`
  border-top: ${RXBorderStyle};
  border-radius: 0.25rem 0.25rem 0 0;
`

export const CloseOffBottomBorder = css`
  border-bottom: ${RXBorderStyle};
  border-radius: 0 0 0.25rem 0.25rem;
`

export const NoColorBackground = css`
  background-color: ${primaryBackgroundColor};
`
export const ContrastBackgroundColor = css`
  background-color: ${contrastBackgroundColor};
`

export const RXCellPaddingV = css`
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
`

export const ListRowLabel = styled.label<{
  positionTop?: boolean
}>`
  grid-row: 1;
  ${({ positionTop }) => (positionTop ? 'align-self: flex-start' : '  align-self: center;')}
  align-self: center;
  grid-column: label;
  font-weight: 500;
  font-size: 0.875rem;
`
ListRowLabel.displayName = 'ListRowLabel'

export const ListRowValue = styled.div.attrs({
  'data-testid': 'value',
})`
  color: ${props => props.color || bodyPrimaryColor};
  grid-row: 1;
  align-self: center;
  grid-column: value;
  display: flex;
  padding-right: 0.625rem;
  overflow: visible;
  > button {
    flex: 1;
  }
`

export const ListRowEyeButton = styled.div.attrs({
  'data-testid': 'eyeButton',
})`
  grid-row: 1;
  align-self: center;
  justify-content: center;
  grid-column: eyeButton;
  display: flex;
  overflow: visible;
  > button {
    flex: 1;
  }
`

export const ListRowValueColumn = styled(ListRowValue)`
  flex-direction: column;
`

const RowIcon = styled.span`
  grid-row: 1;
  align-self: center;
  grid-column: icon;
  font-weight: 500;
  padding-right: 0.325rem;
  font-size: 0.875rem;
  cursor: pointer;
`

export const ListRowExtraValue = ({ children }: ChildProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <>
      <ListRowIcon isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)} />
      {isExpanded && <ListRowExpanded>{children}</ListRowExpanded>}
    </>
  )
}

interface ListRowIconProps {
  isExpanded: boolean
  onClick: () => void
}

export const ListRowIcon = ({ onClick, isExpanded }: ListRowIconProps): JSX.Element => (
  <RowIcon onClick={onClick}>
    <IconWrapper>
      {isExpanded ? <CollapseIcon fill={bodyPrimaryColor} /> : <ExpandIcon fill={bodyPrimaryColor} />}
    </IconWrapper>
  </RowIcon>
)

export const ListRowExpanded = ({ children }: ChildProps): JSX.Element => (
  <>
    <div />
    <div>{children}</div>
  </>
)

export const PaddingBlock = styled.div`
  min-height: 4rem;
`
PaddingBlock.displayName = 'PaddingBlock'

export const SmallPaddingBlock = styled.div`
  min-height: 1.5rem;
`
SmallPaddingBlock.displayName = 'SmallPaddingBlock'

export interface StyledRXImageCellProps {
  noBorder?: boolean
  closeOffTopBorder?: boolean
  closeOffBottomBorder?: boolean
  noColorBackground?: boolean
  fullWidth?: boolean
}

const FullWidth = css`
  grid-column: 1 / span 3;
`

export const RXImageCell = (props: StyledRXImageCellProps & OptChildProps): JSX.Element => {
  const [rxImageIsPic] = useRxImageIsPic()
  const shouldApplyInterfaceImprovments = useFlag(LaunchDarkly.FeatureFlags.PV1_INTERFACE_CONSISTENCY)

  const { children, ...otherProps } = props
  if (rxImageIsPic) {
    return <StyledRXImageCell {...otherProps} />
  } else {
    return (
      <StyledRXImageCell
        {...otherProps}
        shouldApplyInterfaceImprovments={shouldApplyInterfaceImprovments}
        style={
          shouldApplyInterfaceImprovments
            ? {
                paddingLeft: '2rem',
              }
            : {}
        }
      >
        {props.children}
      </StyledRXImageCell>
    )
  }
}
RXImageCell.displayName = 'RXImageCell'

export const StyledRXImageCell = styled.ul.attrs((props: any) => ({
  'data-test-column': 'left',
  shouldApplyInterfaceImprovments: props.shouldApplyInterfaceImprovments,
}))<StyledRXImageCellProps>`
  position: relative;
  ${RXCellPaddingV}
  grid-row: 1 / span 1;
  grid-column: image;
  border-left: ${RXBorderStyle};
  border-right: ${RXBorderStyle};
  display: grid;
  grid-template-rows: auto;
  align-items: stretch;
  ${props =>
    props.shouldApplyInterfaceImprovments
      ? `grid-template-columns: [label] 7rem [value] 1fr;`
      : `
      grid-template-columns: [label] 11rem [value] 1fr;
      font-family: monospace;
      > label {
        padding-left: 1.25rem;
        font-weight: 550;
      }
    `}
  > h4 {
    font-family: roboto, sans-serif;
  }
  :empty {
    ${NoColorBackground}
  }
  ${({ noColorBackground }) => noColorBackground && NoColorBackground}
  ${({ closeOffTopBorder }) => closeOffTopBorder && CloseOffTopBorder}
  ${({ closeOffBottomBorder }) => closeOffBottomBorder && CloseOffBottomBorder}
  ${({ noBorder }) => noBorder && 'border: none;'}
  ${({ fullWidth }) => fullWidth && FullWidth}
`
StyledRXImageCell.displayName = 'StyledRXImageCell'

export const RXCenterAndRightCell = styled.ul`
  ${RXCellPaddingV}
  grid-row: 1;
  grid-column: center / span 2;
`
RXCenterAndRightCell.displayName = 'RXCenterAndRightCell'

export const RXCenterColumn = styled.ul.attrs({
  'data-test-column': 'center',
})`
  ${RXCellPaddingV}
  grid-row: 1;
`
RXCenterColumn.displayName = 'RXCenterColumn'

export const RejectionMessageCell = styled(RXCenterColumn)<StyledRXImageCellProps>`
  grid-row: 1 / span 7;
  ${({ closeOffBottomBorder }) => closeOffBottomBorder && CloseOffBottomBorder}
`
RejectionMessageCell.displayName = 'RejectionMessageCell'

export const ColorlessPadding = css`
  padding-left: 0;
  padding-right: 0;
  margin-left: 1.5rem;
  background-color: white;
`

export const RXRowLeftOverflow = styled.ul`
  grid-row: 1;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  grid-column: center;
  li:nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
  > li {
    padding-left: 0.3125rem;
  }
`
RXRowLeftOverflow.displayName = 'RXRowLeftOverflow'

export const RXCenterCell = styled.li.attrs({
  'data-test-column': 'center',
})<{
  hideSidePadding?: boolean
  overflow?: boolean
  showIcon?: boolean
}>`
  align-items: flex-start;
  ${RXCellPaddingV}
  grid-row: 1;
  ${({ overflow }) => (overflow ? 'grid-column: center / span 2;' : 'grid-column: center;')}
  display: grid;
  padding-left: 2rem;
  grid-template-rows: auto;
  grid-template-columns: [label] 7rem [value] 1fr ${({ showIcon }) => (showIcon ? '[icon] 1.25rem' : '')};
  ${({ hideSidePadding }) => hideSidePadding && ColorlessPadding}
  &.highlighted {
    background-color: #fff6e8;
    border-left: 0.25rem solid #af5304;
    border-radius: 0.25rem;
  }
`
RXCenterCell.displayName = 'RXCenterCell'

export const RXCenterCellHeader = styled(RXCenterCell)`
  padding-left: 1.25rem;
`
RXCenterCellHeader.displayName = 'RXCenterCellHeader'

export const RXCell = styled.li<{ showIcon?: boolean }>`
  align-items: flex-start;
  ${RXCellPaddingV}
  display: grid;
  grid-template-rows: auto;
  padding-left: 0.625rem;
  grid-template-columns: [label] 6rem [value] 1fr ${({ showIcon }) => (showIcon ? '[icon] 1.25rem' : '')};
`
RXCell.displayName = 'RXCell'

export const RXLeftCell = styled(RXCell)`
  grid-row: 1;
  grid-column: 1;
`
RXLeftCell.displayName = 'RXLeftCell'

export const RXCenterCellSimple = styled.li`
  position: relative;
  ${RXCellPaddingV}
  padding-left: 0.625rem;
  grid-row: 1;
  grid-column: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  :empty {
    ${NoColorBackground}
  }
 }
`
RXCenterCellSimple.displayName = 'RXCenterCellSimple'

export const RXRightCell = styled.li.attrs({
  'data-test-column': 'right',
})`
  position: relative;
  ${RXCellPaddingV}
  padding-left: 0.625rem;
  grid-row: 1;
  grid-column: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-wrap: anywhere;
  :empty {
    ${NoColorBackground}
  }
  &.highlighted {
    background-color: #fff6e8;
    border-left: 0.25rem solid #af5304;
    border-radius: 0.25rem;
  }
 }
`
RXRightCell.displayName = 'RXRightCell'

export const RXLeftCellColumn = styled(RXRightCell)`
  padding-left: 0;
`
RXLeftCellColumn.displayName = 'RXLeftCellColumn'

export const RXRightCellVerticalOverflow = styled.li`
  position: relative;
  height: 1rem;
  overflow-y: visible;
  ${RXCellPaddingV}
  padding-left: 0.5rem;
  grid-row: 1;
  grid-column: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  :empty {
    ${NoColorBackground}
  }
 }
`
RXRightCellVerticalOverflow.displayName = 'RXRightCellVerticalOverflow'

export const FullWidthHeader = styled(FilledHeader)`
  grid-row: 1;
  ${FullWidth}
`
FullWidthHeader.displayName = 'FullWidthHeader'

export const ImageFilledHeader = styled(FilledHeader)`
  grid-column: 1 / span 2;
`
ImageFilledHeader.displayName = 'ImageFilledHeader'

export const TwoColumnFilledHeader = styled(FilledHeader)`
  grid-column: 1 / span 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
TwoColumnFilledHeader.displayName = 'TwoColumnFilledHeader'

export const CenterFilledHeader = styled(FilledHeader)`
  grid-column: 2;
`
CenterFilledHeader.displayName = 'CenterFilledHeader'

export const RightFilledHeader = styled(FilledHeader)`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
RightFilledHeader.displayName = 'RightFilledHeader'

export const RXRowTable = styled.ul`
  grid-row: content;
`
RXRowTable.displayName = 'RXRowTable'

export const RXRow = styled.ul<{ transparent?: boolean }>`
  ${ThreeColumnDivision}
  ${({ transparent }) => transparent && NoColorBackground}
`
RXRow.displayName = 'RXRow'

export const RXRowSingle = styled.ul<{ transparent?: boolean }>`
  ${({ transparent }) => transparent && NoColorBackground}
`
RXRowSingle.displayName = 'RXRowSingle'

export const RXRowTwoColumns = styled.ul<{ transparent?: boolean }>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [image] minmax(26.5rem, 1fr) [center] minmax(26.5rem, 1fr);
  ${({ transparent }) => transparent && NoColorBackground}
`

export const AlternatingShading = css`
  :nth-of-type(${() => `even` || `odd`}) {
    > * {
      background-color: ${contrastBackgroundColor};
    }
    > *.highlighted {
      background-color: #fde8c9;
    }
  }
`

export const ErrorText = css`
  color: ${alertRed};
`

export const RXListRowSingle = styled(RXRowSingle)<{ noStripe?: boolean; error?: boolean }>`
  ${({ error }) => error && ErrorText}
  ${({ noStripe }) => !noStripe && AlternatingShading}
`
RXListRowSingle.displayName = 'RXListRowSingle'

export const RXListRow = styled(RXRow)<{ noStripe?: boolean; error?: boolean }>`
  ${({ error }) => error && ErrorText}
  ${({ noStripe }) => !noStripe && AlternatingShading}
`
RXListRow.displayName = 'RXListRow'

export const RXListRowTwoColumns = styled(RXRowTwoColumns)<{
  noStripe?: boolean
  error?: boolean
  hideImageColumn?: boolean
}>`
  ${({ error }) => error && ErrorText}
  ${({ noStripe }) => !noStripe && AlternatingShading}
  grid-template-columns: ${({ hideImageColumn }) =>
    hideImageColumn
      ? '[image] 0 [center] minmax(26.5rem, 1fr)'
      : '[image] minmax(26.5rem, 1fr) [center] minmax(26.5rem, 1fr)'}
`

export const RXTitleRow = styled(RXRow)`
  position: relative;
  background-color: ${primaryBackgroundColor};
`

export const SubTitle = styled.h4`
  font-weight: 500;
  font-size: 1rem;
`

export const LeftSubTitle = styled(SubTitle)`
  grid-row: 1;
  grid-column: 1 / span 2;
`

export const RightSubTitle = styled(SubTitle)`
  grid-row: 1;
  grid-column: 2;
`

export const StyledTextInput = styled(TextInput)`
  margin-right: 1rem;
  height: 2rem;
  border: ${RXBorderStyle};
  background-color: ${primaryBackgroundColor};
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1rem;
  font-family: 'Roboto', sans-serif;
`

export const StyledTextArea = styled(TextArea)`
  min-height: 4rem;
  border: ${RXBorderStyle};
  background-color: ${primaryBackgroundColor};
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.25rem;
  font-family: 'Roboto', sans-serif;
  resize: none;
`

export const StyledSelect = styled(Select)`
  width: 100%;
  flex: 1;
  align-self: center;
  background-color: ${primaryBackgroundColor};
  overflow: visible;
  min-height: 2rem;
`

export const CompoundLabel = styled.p`
  padding: 0.4rem 0;
`

export const CompoundValue = styled.div.attrs({
  'data-testid': 'value',
})`
  display: flex;
  flex-direction: column;
  padding-right: 0.625rem;
`

export const CompoundTextInput = styled(StyledTextInput)`
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
`

export const CompoundTextArea = styled(StyledTextArea)`
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
`

export const RaisedAnchor = styled.a`
  position: absolute;
  top: -8rem;
`

export const VerticalStackGroupListStyle = css`
  border-radius: 0.25rem;
  border-left: 0.25rem solid;
  border-right: 0.25rem solid;
  border-color: ${contrastBackgroundColor};
  margin-top: 3px;
  margin-bottom: 3px;
`

export const VerticalStackItemStyle = css`
  align-self: stretch;
  border-left: 4px solid;
  border-right: 4px solid;
  border-radius: 0.5rem;
  border-color: white;
  background: white;
  display: grid;
  grid-gap: 0.25rem;
  padding: 0.5rem;
  font-size: 1em;
  cursor: pointer;
`

export const AliasMarker = styled.span`
  font-style: italic;
  opacity: 0.6;
`

export const ExtraValueEntry = styled.div`
  margin-top: 0.6rem;
`

interface DiffTooltipProps {
  message: string | undefined
}
export const DiffTooltip = (props: DiffTooltipProps): JSX.Element => {
  const message = props.message || 'value is different from prescription to system data'
  return (
    <Tooltip label={message}>
      <IconContainer>
        <InfoIcon fill={alertRed} />
      </IconContainer>
    </Tooltip>
  )
}

const IconContainer = styled.div`
  width: 22px;
`
