import type { ReactNode } from 'react'
import { memo, useEffect, useMemo, useRef } from 'react'
import { Box, Button, Text } from '@truepill/react-capsule'
import type { ApolloError } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { capsuleBorderColor, capsuleGrayColor } from 'styles/styleVariables'
import type { Log, UserFirstLastName } from 'types'

interface EditListItemProps {
  user: UserFirstLastName
  date: string
  children: ReactNode
}

interface EditListItemsProps {
  edits: Log[]
}

const EditListItems = ({ edits }: EditListItemsProps) => {
  const containerRef = useRef<HTMLUListElement>(null)

  // Process edits to determine added and removed items
  const processedEdits = useMemo(() => {
    return edits.map((edit: Log) => {
      const currentValues: string[] = JSON.parse(edit.change?.newValue ?? '[]')
      const oldValues: string[] = JSON.parse(edit.change?.oldValue ?? '[]')

      // Identify added and removed edits and split values
      const added = currentValues.filter(value => !oldValues.includes(value)).join(', ')
      const removed = oldValues.filter(value => !currentValues.includes(value)).join(', ')

      return {
        id: edit._id,
        added,
        createdAt: edit.createdAt,
        removed,
        user: edit.user,
      }
    })
  }, [edits])

  // Scroll to the bottom of the container to show the latest edit
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [edits])

  if (!processedEdits.length) {
    return <p>No edit history</p>
  }

  return (
    <EditListItemsContainer ref={containerRef}>
      {processedEdits.map(
        ({ id, added, createdAt, removed, user }) =>
          (added || removed) && (
            <EditListItem key={id} user={user} date={createdAt}>
              <p>
                {added && removed ? `${removed} modified to ${added}` : added ? `Added ${added}` : `Removed ${removed}`}
              </p>
            </EditListItem>
          ),
      )}
    </EditListItemsContainer>
  )
}

const EditListItem = ({ date, user, children }: EditListItemProps) => {
  return (
    <li data-testid="EditListItem">
      <Box css={{ display: 'flex', gap: '0.5rem' }}>
        <Box css={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
          <EditListItemTitle>
            <span>{dayjs(date).format('MM/DD/YYYY hh:mmA')}</span>
            <span>&#x2022;</span>
            <span>{`${user.firstName} ${user.lastName}`}</span>
          </EditListItemTitle>
          {children}
        </Box>
      </Box>
    </li>
  )
}

const EditHistory = memo(
  ({
    onExitClick,
    isFetchingEdits,
    errorFetchingEdits,
    edits,
  }: {
    onExitClick?: () => void
    isFetchingEdits: boolean
    errorFetchingEdits: ApolloError | undefined
    edits: Log[]
  }) => {
    if (errorFetchingEdits) {
      return <p>An error ocurred while loading the edits</p>
    }

    if (isFetchingEdits) {
      return (
        <Container>
          <Wrapper>
            <LoadingSpinnerWrapper>
              <span>
                <LoadingSpinner />
              </span>
            </LoadingSpinnerWrapper>
          </Wrapper>
        </Container>
      )
    }

    return (
      <Container>
        <ShrinkableWrapper>
          <Button
            css={{ display: 'flex', textDecoration: 'underline', padding: 0 }}
            variant="primary-text"
            type="button"
            onClick={onExitClick}
          >
            Return to clinical survey
          </Button>
        </ShrinkableWrapper>
        <Wrapper>
          <Box>
            <Title>Edit History</Title>
            <SubTitle>Modifications made to the patient's clinical survey</SubTitle>
          </Box>
          <EditListItems edits={edits} />
        </Wrapper>
      </Container>
    )
  },
)

const ShrinkableWrapper = styled(Box)`
  background: ${capsuleGrayColor};
  padding: 1rem;
  padding-left: 1rem;
  margin-top: 1rem;
  position: sticky;
  top: 0;
`

const Wrapper = styled(Box)`
  align-items: flex-start;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const Title = styled(Text)`
  line-height: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
`

const SubTitle = styled(Text)`
  line-height: 1.5rem;
`

const Container = styled(Box)`
  background-color: ${capsuleGrayColor};

  border-radius: 0.5rem;
  max-height: 60vh;
  overflow: scroll;
`

const EditListItemsContainer = styled.ul`
  font-size: 1rem;
  font-family: 'lato';
  gap: 2rem;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
`

const EditListItemTitle = styled(Box)`
  display: flex;
  gap: 0.25rem;
  flexwrap: wrap;
  font-size: 1rem;
  font-weight: bold;
  color: ${capsuleBorderColor};
`

const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  > span {
    width: 3rem;
    opacity: 0.3;
    display: block;
  }
`

export default EditHistory
