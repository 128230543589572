import { UserRoles } from '@truepill/tpos-types'
import AuthLimited from 'components/AuthLimited'
import { ActionButtonContainer } from 'components/PageStructure'
import useOrderLock from 'hooks/useOrderLock'
import type { Fill, Order } from 'types'
import { isOrderReadOnly } from 'utils'
import { ConfirmButton } from './buttons/ConfirmButton'
import { RejectButton } from './buttons/RejectButton'
import { ReprintButton } from './buttons/ReprintButton'
import { SendBackButton } from './buttons/SendBackButton'

type ActionButtonsProps = { order: Order; fill: Fill; disableRphButtons: boolean }

const ActionButtons = ({ fill, order, disableRphButtons }: ActionButtonsProps): JSX.Element => {
  const { orderEditable } = useOrderLock(order._id)
  const orderReadonly = isOrderReadOnly(order)
  const editable = orderEditable && !orderReadonly

  return (
    <ActionButtonContainer>
      <AuthLimited
        roles={[
          UserRoles.Pharmacist,
          UserRoles.LeadPharmacist,
          UserRoles.Technician,
          UserRoles.LeadCustomerSupport,
          UserRoles.CustomerSupport,
        ]}
      >
        <ReprintButton order={order} fill={fill} orderEditable={editable} />
      </AuthLimited>
      <AuthLimited roles={[UserRoles.Pharmacist]}>
        <RejectButton order={order} fill={fill} orderEditable={editable} />
      </AuthLimited>
      <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.LeadPharmacist]}>
        <SendBackButton order={order} fill={fill} orderEditable={editable} />
      </AuthLimited>
      <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.LeadPharmacist]}>
        <ConfirmButton order={order} fill={fill} orderEditable={editable} disableRphButtons={disableRphButtons} />
      </AuthLimited>
    </ActionButtonContainer>
  )
}

export default ActionButtons
