import { useMemo, useState } from 'react'
import { Autocomplete } from '@truepill/react-capsule'
import { useQuery } from '@truepill/tpos-react-router'
import { PrinterPurpose } from '@truepill/tpos-types'
import { LIST_PRINTERS } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import { usePrintProvider } from 'providers/PrintProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import type { Printer, TPOSLocation } from 'types'

const makePrinterOption = (printer: Printer, getLocationNameById: { (locationId: string): string }) => ({
  label: `${printer.printerName} - ${getLocationNameById(printer.locationId)}`,
  value: printer,
})

type PrinterSelectProps = {
  showOpenAsPdf?: boolean
  helperText?: string
  state?: 'default' | 'error'
  label?: string
  printerType?: string
  locationId?: TPOSLocation['_id']
  modal?: boolean
  withCapsule?: boolean
  printerPurpose?: PrinterPurpose
  onChange: (printer?: Printer | string) => void
  defaultPrinter?: Printer
}

type PrinterOptionType = { label: string; value: Printer | string }

const PrinterSelect = ({
  showOpenAsPdf = true,
  locationId,
  onChange,
  printerPurpose,
  label,
  helperText,
  state,
  printerType,
  defaultPrinter,
}: PrinterSelectProps): JSX.Element => {
  const { selectedPrinter } = usePrintProvider()
  const { getLocationNameById } = useTPCacheContext()

  // We keep an internal trackof the selected printer because this selector
  // should not automatically set the provider (eg: it might be used for one
  // off jobs or shouldn't be automatically set until a dialog is submitted)
  // Components which render the PrinterSelect should be able to choose
  // what they want the result to do.
  const [chosenPrinter, setChosenPrinter] = useState<Printer | null>(defaultPrinter ?? null)
  const [openAsPdf, setOpenAsPdf] = useState(false)

  const showErrorToast = useErrorToast()

  const variables = useMemo(() => {
    const vars: { [key: string]: string } = {}

    if (locationId !== undefined) {
      vars.locationId = locationId
    }

    if (printerType) {
      vars.printerType = printerType
    }

    if (printerPurpose) {
      vars.printerPurpose = printerPurpose
    }

    return vars
  }, [locationId, printerPurpose, printerType])

  const { data, error, loading } = useQuery(LIST_PRINTERS, {
    variables,
    onCompleted: ({ getPrinters }) => {
      if (getPrinters?.some((printer: Printer) => printer._id === selectedPrinter?._id)) {
        setChosenPrinter(selectedPrinter ?? null)
      }
    },
  })

  const options = useMemo(() => {
    if (loading) {
      return [{ label: 'Loading printers...', value: 'loading' }]
    }

    if (error) {
      showErrorToast('Failed to load printers:' + error.message)
      return [{ label: 'Failed to load printers...', value: 'error' }]
    }

    const options = data?.getPrinters?.map((printer: Printer) => makePrinterOption(printer, getLocationNameById))
    const displayAsPdf = printerPurpose === PrinterPurpose.RxLabel

    return displayAsPdf && showOpenAsPdf ? [...options, { label: 'Open as PDF', value: 'pdf' }] : options
  }, [loading, error, data?.getPrinters, printerPurpose, showErrorToast, getLocationNameById, showOpenAsPdf])

  const currentOption = options?.find((option: PrinterOptionType) => {
    if (chosenPrinter) {
      return (option?.value as Printer)?._id === chosenPrinter?._id
    }
    return openAsPdf ? option?.value === 'pdf' : false
  })

  const handleOnChange = (option: PrinterOptionType | null) => {
    const { value } = option ?? {}

    if (!value) {
      setOpenAsPdf(false)
      setChosenPrinter(null)
    } else if (value === 'pdf') {
      setOpenAsPdf(true)
      setChosenPrinter(null)
    } else if (!['loading', 'error'].includes(value as string)) {
      setOpenAsPdf(false)
      setChosenPrinter(value as Printer)
    }

    onChange(value)
  }

  return (
    <Autocomplete
      helperText={helperText}
      state={state}
      type="search"
      label={label}
      variant="small"
      selectedKey="label"
      options={options}
      placeholder="Select a printer..."
      value={currentOption}
      onChange={handleOnChange}
    />
  )
}

export default PrinterSelect
