import type { PrescriberAddress } from '@truepill/tpos-types'
import { isEmpty } from 'lodash'
import type { DeepNullable } from 'ts-essentials'
import type { Address, Prescription } from 'types'

export const isCompleteAddress = (address: Address): boolean =>
  Boolean(address.street1) && Boolean(address.city) && Boolean(address.state) && Boolean(address.zip)

export const getPrescriberAddress = (prescription: Prescription): PrescriberAddress | undefined => {
  const { prescriber, prescriberAddressId } = prescription

  if (!prescriber?.addresses?.length) return undefined
  if (!prescriberAddressId) return prescriber.addresses[0]

  for (const practiceAddress of prescriber.addresses) {
    if (practiceAddress._id?.toString() === prescriberAddressId.toString()) {
      return practiceAddress
    }
  }

  return undefined
}

export const getPrescriberAddressWithFax = (prescription: Prescription): PrescriberAddress | undefined => {
  const { prescriber, prescriberAddressId } = prescription

  const returnAddress = getPrescriberAddress(prescription)

  if (prescriberAddressId && returnAddress && !returnAddress?.fax) {
    const practiceAddress = prescriber.addresses.find(
      practiceAddress =>
        practiceAddress.phone &&
        returnAddress.phone &&
        practiceAddress.phone.replace(/\D/g, '') === returnAddress.phone.replace(/\D/g, '') &&
        practiceAddress.fax,
    )

    if (practiceAddress) {
      return {
        ...returnAddress,
        fax: practiceAddress.fax,
      }
    }
  }

  return returnAddress
}

export const checkIfAddressChanged = (
  addr1: Partial<PrescriberAddress> | undefined,
  addr2: Partial<PrescriberAddress> | undefined,
): boolean => {
  if (!addr1 || !addr2) return true

  return !(
    addr1.street1 === addr2.street1 &&
    addr1.street2 === addr2.street2 &&
    addr1.city === addr2.city &&
    addr1.state === addr2.state &&
    addr1.zip === addr2.zip
  )
}

export const formatAddress = (address: DeepNullable<Address> | undefined): string => {
  if (!address) return ''
  const { street1, street2, city, state, zip } = address
  return `${street1}${street2 ? `, ${street2}` : ''}${city && state && zip ? `, ${city}, ${state} ${zip}` : ''}`
}

type ValidationResult = { success: true } | { success: false; reason: string }

const validateZip = (zip: string | null | undefined): ValidationResult => {
  if (!zip) return { success: false, reason: 'Missing zip' }
  if ((zip.length || 0) < 5) {
    return { success: false, reason: '5 digits minimum' }
  }

  if (!/(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)/.test(zip)) {
    return { success: false, reason: 'Zip must follow the correct format' }
  }

  return { success: true }
}

export const validateAddressForm = (
  address: Partial<DeepNullable<Address>> | undefined,
  isOptional = false,
): { isValid: boolean; errors: Record<keyof Address, string> } => {
  const errors: Record<string, string> = {}

  if (!isOptional || (address && !Object.values(address || {}).every(val => !val))) {
    const { street1, city, state, zip } = address || {}
    const requiredFields = {
      street1,
      city,
      state,
      zip,
    }

    const zipValidationResult = validateZip(zip)
    if (!zipValidationResult.success) {
      errors.zip = zipValidationResult.reason
    }

    Object.entries(requiredFields).forEach(([key, value]) => {
      if (!value) {
        errors[key] = 'Missing field'
      }
    })
  }

  return { isValid: isEmpty(errors), errors }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAddress = (object: any): object is Address => {
  return 'street1' in object
}

export const compareAddresses = (address1: Address, address2: Address) => {
  return (
    address1.street1 === address2.street1 &&
    address1.street2 === address2.street2 &&
    address1.city === address2.city &&
    address1.state === address2.state &&
    address1.zip === address2.zip
  )
}
