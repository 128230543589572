import type { EntityId, Address } from '../index'

export interface MatchAddressProps {
  incomingAddress: Address
  incomingPhone: string
  incomingFax?: string
  incomingSpi?: string
}

export interface PrescriberAddress extends Address {
  _id?: string
  spi?: string
  surescriptsKey?: string
  isActive?: boolean
  isArchived?: boolean
  serviceLevel?: string[]
  businessName?: string
  phone?: string
  fax?: string
  lastModifiedDate?: Date
}

export interface Supervisor {
  _id: string
  firstName: string
  lastName: string
  npi: string
}

export interface PrescriberAddressWithSupervisor extends PrescriberAddress {
  supervisor?: Supervisor
}

export interface PrescriberPractice {
  _id?: EntityId
  address: Address
  name?: string
  phone: string
  fax?: string
  spi?: string
  surescriptsKey?: string
  isActive?: boolean
  serviceLevel?: [string]
  isArchived?: boolean
}

export interface PrescriberLookup {
  firstName: string
  lastName: string
  npi?: string
  stateLicense?: string
  businessName?: string
  spi?: string
  dea?: string
}

export interface PrescriberContacts {
  phone: string
  email?: string
  fax?: string
}

export interface PrescriberCreate extends PrescriberLookup {
  stateLicense?: string
  contacts: PrescriberContacts
  addresses: Address[]
  suffix?: ProviderType
}

export interface PrescriberSave extends PrescriberLookup {
  npi?: string
  addresses?: PrescriberAddress[]
  contacts?: Partial<{ fax: string; phone: string; email: string }>
  stateLicense?: string
  suffix?: ProviderType
}

export enum ProviderType {
  OD = 'OD',
  PMHNP = 'PMHNP',
  MBBS = 'MBBS',
  DNP = 'DNP',
  DO = 'D.O.',
  DPT = 'DPT',
  DDS = 'D.D.S.',
  FNP = 'F.N.P.',
  CPP = 'CPP',
  ND = 'N.D.',
  PA = 'P.A.',
  APNP = 'APNP',
  VMD = 'V.M.D.',
  RPh = 'RPh',
  APRN = 'APRN',
  DCAPC = 'DC APC',
  PSYD = 'PsyD',
  CRNA = 'CRNA',
  PharmD = 'PharmD',
  BVMS = 'B.V.M.S.',
  WHNP = 'WHNP',
  PHD = 'PhD',
  ARNP = 'ARNP',
  PT = 'PT',
  CRNP = 'CRNP',
  DPM = 'DPM',
  CNP = 'CNP',
  N_P_ = 'N.P.',
  NP = 'NP',
  CDH = 'CDH',
  DMD = 'D.M.D.',
  DVM = 'D.V.M.',
  MD = 'M.D.',
  CNM = 'CNM',
}
