import { useEffect, useState } from 'react'
import { Chip } from '@truepill/react-capsule'
import type { ApolloError } from '@truepill/tpos-react-router'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import type { FuzzySearchProps } from 'modals/DurInputMappingsModal/types'
import { capsuleDarkRedColor, capsuleRed } from 'styles/styleVariables'
import type { DURSubject } from 'types'

export const isReal = <TValue extends { type?: string }>(
  value: TValue | null | undefined,
  // @ts-expect-error my TS knowlegde is failing here
): value is DeepRequired<TValue> => value !== null && value !== undefined

interface Props extends FuzzySearchProps {
  suggestions: DURSubject[]
  error?: ApolloError
}

const FuzzySearchAutoComplete = ({
  error,
  hotKey = 'c',
  idKey,
  label,
  onChange,
  onError,
  onResultDismiss,
  onSelect,
  searchTerm = '',
  suggestions,
  value,
}: Props): JSX.Element => {
  const [showError, setShowError] = useState(true)
  const hasError = showError && !!error
  const showErrorMessage = searchTerm && !suggestions.length

  useEffect(() => {
    if (hasError) {
      onError?.()
    }
  }, [hasError, onError])

  return (
    <>
      <SearchAutoComplete
        allowCustomInput={false}
        autoCompleteSuggestions={suggestions}
        hotKey={hotKey}
        idKey={idKey}
        label={label}
        onChange={onChange}
        onSelect={(chosen: DURSubject) => {
          onSelect?.(chosen)
          onChange?.('')
        }}
        placeholder=""
        retainFocusAfterSelection
        suggestionComponent={({
          name,
          highlighted,
          onClick,
          value,
        }: DURSubject & { highlighted: boolean; onClick: () => void }) => (
          <DropDownOption data-testid="suggestion-item" highlighted={highlighted} onClick={onClick}>
            <p>
              {name} {name?.length ? <>({value})</> : value}
            </p>
          </DropDownOption>
        )}
        value={hasError ? '' : searchTerm}
        withCapsule
      ></SearchAutoComplete>

      {hasError && (
        <Chip
          onDismiss={() => setShowError(false)} // Update state to hide the error
          css={{
            backgroundColor: capsuleDarkRedColor,
            color: 'white',
            position: 'absolute',
            top: '12px',
            left: '45px',
          }}
        >
          Error
        </Chip>
      )}

      {!hasError && showErrorMessage && (
        <div style={{ color: capsuleRed, marginTop: '0.5rem', fontSize: '0.875rem' }}>No Medispan matches found.</div>
      )}

      {value && !hasError && (
        <Chip onDismiss={onResultDismiss} css={{ position: 'absolute', top: '12px', left: '35px' }}>
          {value.name}
        </Chip>
      )}
    </>
  )
}

export default FuzzySearchAutoComplete
