import type {
  Address,
  CommunicationNumbers,
  DirectTransferPatient,
  DirectTransferPrescriber,
  DirectTransferPrescription,
} from '@truepill/tpos-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import type {
  Escript,
  EscriptPrescriber,
  EscriptMedicationPrescribed,
  EscriptPatient,
  Patient,
  Prescriber,
  Customer,
  Prescription,
} from 'types'

dayjs.extend(utc)

export interface RxImagePrescriber {
  firstName?: string
  lastName?: string
  npi?: string
  stateLicense?: string
  address?: Address
  communicationNumbers?: CommunicationNumbers
  deaNumber?: string
  suffix?: string
  customer?: string | number
  prescriberType?: string
}

export const getRxImagePrescriber = (
  escriptPrescriber?: EscriptPrescriber,
  directTransferPrescriber?: DirectTransferPrescriber,
): RxImagePrescriber => {
  const directTransferPrescriberCommunication: any = {}

  if (directTransferPrescriber?.address?.phone) {
    Object.assign(directTransferPrescriberCommunication, {
      phone: { number: directTransferPrescriber.address.phone },
    })
  }

  if (directTransferPrescriber?.address?.email) {
    Object.assign(directTransferPrescriberCommunication, { email: directTransferPrescriber.address.email })
  }

  const directTransferPrescriberCommunicationNumbers = Object.keys(directTransferPrescriberCommunication).length
    ? directTransferPrescriberCommunication
    : undefined

  return {
    firstName: escriptPrescriber?.firstName || directTransferPrescriber?.firstName,
    lastName: escriptPrescriber?.lastName || directTransferPrescriber?.lastName,
    npi: escriptPrescriber?.npi || directTransferPrescriber?.npi,
    address: escriptPrescriber?.address || directTransferPrescriber?.address,
    communicationNumbers: escriptPrescriber?.communicationNumbers || directTransferPrescriberCommunicationNumbers,
    deaNumber: escriptPrescriber?.deaNumber,
    suffix: escriptPrescriber?.suffix || directTransferPrescriber?.suffix,
    stateLicense: escriptPrescriber?.stateLicenseNumber || directTransferPrescriber?.stateLicense,
    prescriberType: directTransferPrescriber?.prescriberType,
  }
}

export const getRxImagePrescriberManualEntry = (prescriber: Prescriber, customer?: Customer): RxImagePrescriber => {
  return {
    firstName: prescriber?.firstName,
    lastName: prescriber?.lastName,
    npi: prescriber?.npi,
    address: prescriber?.addresses?.[0], //prescriber?.address,
    communicationNumbers: {
      phone: { number: prescriber?.contacts?.phone || '' },
      fax: [{ number: prescriber?.contacts?.fax || '' }],
    },
    deaNumber: prescriber?.dea,
    suffix: prescriber?.suffix,
    customer: customer?.name,
    stateLicense: prescriber?.stateLicense,
  }
}

export interface RxImagePrescription {
  ndc?: string
  drugDescription?: string
  quantity?: number
  daysSupply?: number
  numberOfRefills?: number
  sig?: string
  writtenDate?: string
  expirationDate?: string
  note?: string
}

export const getRxImagePrescription = (
  escript?: Escript,
  directTransferPrescription?: DirectTransferPrescription,
): RxImagePrescription => {
  const escriptMedicationPrescribed = escript?.medicationPrescribed as EscriptMedicationPrescribed
  const escriptWrittenDate = escriptMedicationPrescribed?.writtenDate

  const writtenDate: string | undefined = escriptWrittenDate
    ? dayjs.utc(escriptWrittenDate).format('YYYY-MM-DD')
    : directTransferPrescription?.writtenDate

  // expirationDate is not provided from EscriptMedication/DT Prescriptions, should default to one year from writtenDate.
  const expirationDate: string | undefined = writtenDate
    ? dayjs(writtenDate).add(1, 'year').format('YYYY-MM-DD')
    : undefined

  return {
    ndc: escript?.ndc || directTransferPrescription?.prescribedNdc,
    drugDescription: escriptMedicationPrescribed?.drugDescription || directTransferPrescription?.medicationName,
    quantity:
      escriptMedicationPrescribed?.quantity?.value ||
      (directTransferPrescription?.quantityWritten
        ? Number.parseInt(directTransferPrescription.quantityWritten)
        : undefined),
    daysSupply: escriptMedicationPrescribed?.daysSupply || directTransferPrescription?.daysSupply,
    numberOfRefills: escriptMedicationPrescribed?.numberOfRefills || directTransferPrescription?.refillsLeft,
    sig: escriptMedicationPrescribed?.sig || directTransferPrescription?.medicationSig,
    writtenDate,
    expirationDate,
    note: escriptMedicationPrescribed?.note || directTransferPrescription?.notes,
  }
}

export const getRxImagePrescriptionManualEntry = (prescription?: Prescription): RxImagePrescription => {
  const writtenDate = prescription?.writtenDate

  return {
    ndc: prescription?.ndc,
    drugDescription: prescription?.name,
    quantity: prescription?.quantity ? parseInt(`${prescription?.quantity}`) : undefined,
    daysSupply: prescription?.daysSupply,
    numberOfRefills: prescription?.numberOfRefills,
    sig: prescription?.directions,
    writtenDate,
    expirationDate: prescription?.expirationDate,
    note: prescription?.note,
  }
}

export interface RxImagePatient {
  firstName?: string
  lastName?: string
  dob?: Date | string
  gender?: string
  address?: Address
  communicationNumbers?: CommunicationNumbers
}

export const getRxImagePatient = (
  escriptPatient?: EscriptPatient,
  directTransferPatient?: DirectTransferPatient,
): RxImagePatient => {
  return {
    firstName: escriptPatient?.firstName || directTransferPatient?.firstName,
    lastName: escriptPatient?.lastName || directTransferPatient?.lastName,
    dob: escriptPatient?.dob || directTransferPatient?.dob || undefined,
    gender: escriptPatient?.gender || directTransferPatient?.gender,
    address:
      escriptPatient?.address ||
      (directTransferPatient?.street1
        ? {
            street1: directTransferPatient.street1,
            street2: directTransferPatient.street2,
            city: directTransferPatient.city || '',
            state: directTransferPatient.state || '',
            zip: directTransferPatient.zip || '',
          }
        : undefined),
    communicationNumbers:
      escriptPatient?.communicationNumbers ||
      (directTransferPatient?.phone ? { phone: { number: directTransferPatient?.phone } } : undefined),
  }
}

export const getRxImagePatientManualEntry = (patient: Patient): RxImagePatient => {
  return {
    firstName: patient?.firstName,
    lastName: patient?.lastName,
    dob: patient?.dob,
    gender: patient?.gender,
    address: patient?.address?.home,
    communicationNumbers: {
      phone: {
        number: patient?.contacts?.phone || '',
      },
    },
  }
}
