import { useCallback } from 'react'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import RejectModal from 'modals/RejectModal'
import SimpleModal from 'modals/SimpleModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { bodyPrimaryColor, primaryBackgroundColor } from 'styles/styleVariables'
import type { Fill } from 'types'
import { isOrderReadOnly } from 'utils'

type RejectFillButtonProps = {
  orderId: string
  fill: Fill
  dispensedName: string
  rxNumber: string
  onRejected?: () => void
  isReadOnly?: boolean
}

const RejectFillButton = ({
  orderId,
  fill,
  dispensedName,
  rxNumber,
  onRejected,
  isReadOnly = false,
}: RejectFillButtonProps): JSX.Element => {
  const { showModal } = useModalContext()

  const showConfirmationModal = useCallback(() => {
    showModal(() => (
      <SimpleModal
        hideCancel
        title={'Order modified'}
        confirmButtonLabel={'Okay'}
        icon={<CancelIcon fill={bodyPrimaryColor} />}
      >
        <p>{'This order has been modified and fill has been rejected. Please quarantine this fill.'}</p>
      </SimpleModal>
    ))
  }, [showModal])

  const showRejectModal = useCallback(() => {
    showModal(() => (
      <RejectModal
        showDescription
        description={`Select a reject reason for "RX: ${rxNumber} ${dispensedName}"`}
        itemId={orderId}
        fillId={fill._id}
        confirmationCallback={() => {
          showConfirmationModal()
          onRejected && onRejected()
        }}
        durs={fill.durScreenings?.slice(-1)[0]?.results ?? []}
        isControlledSubstance={!!fill.handlingTags.deaScheduleNum || fill.handlingTags.isControlledSubstance}
      />
    ))
  }, [showModal])

  return (
    <StyledActionButton
      data-testid="reject"
      disabled={isReadOnly}
      icon={
        <RejectIconWrapper title="Reject Fill">
          <CancelIcon fill={bodyPrimaryColor} />
        </RejectIconWrapper>
      }
      onClick={showRejectModal}
    />
  )
}

const RejectIconWrapper = styled(IconWrapper)`
  display: flex;
  grid-column: removeIcon;
  svg {
    fill: #999999;
  }
`

const StyledActionButton = styled(ActionButton)`
  background-color: ${primaryBackgroundColor};
  margin: 1px 0px 0px -3px;
  padding: 0 5px 0 2px;
  border-radius: 4px;
  grid-column: rejectFill;
}
`
export default RejectFillButton
