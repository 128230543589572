import { useMutation } from '@truepill/tpos-react-router'
import {
  OrderStatus,
  RxFillRequestStatus,
  FillStatus,
  CopayStatus,
  LaunchDarkly,
  PV1TriageReasons,
} from '@truepill/tpos-types'
import type { TriageReasons } from '@truepill/tpos-types'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { ReactComponent as LoadingDots } from 'assets/icons/loading_dots.svg'
import ActionButton, { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ModalHeader, ModalWrapper } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { RESOLVE_TRIAGE, RESOLVE_COPAY_TRIAGE } from 'gql'
import { Box } from 'grommet'
import { useOrderLock, useCopayLock } from 'hooks'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { CopayTriage, OrderTriage, Order, CopayRequest, QueueItem } from 'types'
import { isCopayRequest, isOrder, isOrderReadOnly } from 'utils'
import { TriageButtonFactory } from './buttons/triageButton/triageFactory'

type ResolveTriageButtonProps = {
  onClick?: () => void
  onTriageResolved?: (triageId?: string) => void
  onDismiss?: () => void
  item: QueueItem
  fillId?: string
  disabled?: boolean
  triageId?: string
  forceShowTriage?: boolean
  isCapsule?: boolean
  warningMessage?: string
  copayAmountChanged?: boolean
}

const shouldDisableResolveButton = (item: Order | CopayRequest, triageId?: string): boolean => {
  if (!triageId) {
    return true
  }

  const triageToSolve = isOrder(item)
    ? (item.triages.find(
        ({ endDate, _id }: OrderTriage) => !endDate && triageId?.toString() === _id?.toString(),
      ) as OrderTriage)
    : (item.triages.find(
        ({ endDate, _id }: CopayTriage) => !endDate && triageId?.toString() === _id?.toString(),
      ) as CopayTriage)

  if (!triageToSolve) {
    return true
  }

  if (isOrder(item) && !item.isReplacement && item.rxFillRequests.some(({ prescription }) => prescription.isExpired)) {
    return true
  }

  const isPreCheckTriage = triageToSolve.reason.toString() === 'PreCheck'
  if (isPreCheckTriage) {
    return false
  }

  if (triageToSolve.reason.toString() === PV1TriageReasons.PatientOutreachRequired) {
    return false
  }

  // We can't resolve triage if there are more than one infulfillment fills
  // on the prescriptions (possible duplicate orders)
  const hasManyInFulfillmentFills =
    isOrder(item) &&
    item.rxFillRequests.some(
      ({ prescription }) => prescription.fills.filter(({ status }) => status === FillStatus.InFulfillment).length > 1,
    )

  // We can't resolve triage if this is a non-replacement order and
  // a prescription quantity remaining less than the amount already dispensed.
  const hasQuantityError =
    isOrder(item) &&
    !item.isReplacement &&
    item.status === OrderStatus.PV1 &&
    item.rxFillRequests.some(({ fill, prescription, status }) => {
      return (
        status === RxFillRequestStatus.PV1 &&
        fill &&
        !fill.isReplacement &&
        prescription.quantityRemaining < fill.dispensed.quantity
      )
    })

  return hasManyInFulfillmentFills || hasQuantityError
}

const ResolveTriageButton = ({
  onClick,
  onTriageResolved,
  item,
  fillId,
  disabled,
  triageId,
  forceShowTriage,
  onDismiss,
  isCapsule = false,
  warningMessage,
  copayAmountChanged = false,
}: ResolveTriageButtonProps): JSX.Element => {
  const showErrorToast = useErrorToast(true)
  const showSuccessToast = useSuccessToast()
  const { dismissModal, showModal } = useModalContext()
  const isOrderItem = isOrder(item)
  const { orderEditable } = useOrderLock(item._id, !isOrderItem)
  const isOrderEditable = isOrderItem && orderEditable && !isOrderReadOnly(item)
  const { copayEditable } = useCopayLock(item._id, isOrderItem)
  const [resolveOrderTriageMutation] = useMutation<
    {
      resolveTriage: { _id: string; inTriage: { reason: TriageReasons } }
      status: OrderStatus
      triages: { reason: TriageReasons }[]
    },
    { orderId: string; triageId: string }
  >(RESOLVE_TRIAGE, {
    onCompleted() {
      showSuccessToast(`Resolved Triage Order: ${item._id}`)
      onTriageResolved?.(triageId)
      dismissModal()
    },
    onError(err) {
      showErrorToast(`Failed to resolve triage: ${err.message.replace('GraphQL error:', '')}`)
    },
  })

  const shouldEnableTriageButtonForCopay: boolean = useFlag(
    LaunchDarkly.FeatureFlags.USE_COPAY_VALIDATION_ON_RESOLVE_PATIENT_OUTREACH_TRIAGE,
  )

  const [resolveCopayTriageMutation] = useMutation<
    {
      resolveTriage: { _id: string }
      status: CopayStatus
      triages: { reason: TriageReasons }[]
    },
    { copayRequestId: string; triageId: string }
  >(RESOLVE_COPAY_TRIAGE, {
    onCompleted() {
      showSuccessToast(`Resolved Triage Copay: ${item._id}`)
      onTriageResolved?.(triageId)
      dismissModal()
    },
    onError(err) {
      showErrorToast(`Failed to resolve triage: ${err.message.replace('GraphQL error:', '')}`)
    },
  })

  // While the order data is loading, we return a disabled placeholder button
  // to reduce how much the page jumps around. In the future we may also want
  // to catch and retrigger hotkey usage during this loading time.
  if (!item) {
    return (
      <ActionButton
        data-testid="loading"
        disabled={true}
        icon={
          <IconWrapper>
            <DangerIcon fill={bodyPrimaryColor} />
          </IconWrapper>
        }
        label={
          <Box pad={{ left: '1rem', right: '1rem' }}>
            <LoadingDots />
          </Box>
        }
      />
    )
  }

  if (
    (isOrder(item) && !item.inTriage) ||
    (isCopayRequest(item) && item.status !== CopayStatus.Triage) ||
    forceShowTriage
  ) {
    return (
      <TriageButtonFactory
        onClick={onClick}
        item={item}
        fillId={fillId}
        disabled={(isOrderItem && !isOrderEditable) || (!isOrderItem && !copayEditable) || disabled}
        onDismissModal={onDismiss}
        isCapsule={isCapsule}
      />
    )
  }

  const triageToSolve = isOrder(item)
    ? (item.triages.find(
        ({ endDate, _id }: OrderTriage) => !endDate && triageId?.toString() === _id?.toString(),
      ) as OrderTriage)
    : (item.triages.find(
        ({ endDate, _id }: CopayTriage) => !endDate && triageId?.toString() === _id?.toString(),
      ) as CopayTriage)

  let disableResolveTriage = shouldDisableResolveButton(item, triageId)
  if (triageToSolve?.reason === PV1TriageReasons.PatientOutreachRequired) {
    disableResolveTriage = !shouldEnableTriageButtonForCopay && copayAmountChanged
  }

  return (
    <ActionButton
      data-testid="resolve"
      disabled={disableResolveTriage}
      label="Resolve"
      onClick={() =>
        showModal?.(() => (
          <ModalWrapper id="ResolveTriageModal">
            <ModalHeader>
              <IconWrapper>
                <DangerIcon fill={bodyPrimaryColor} />
              </IconWrapper>
              <h2>Resolve Triage</h2>
            </ModalHeader>
            {warningMessage && <p>Warning: {warningMessage}</p>}
            <Box direction="row" justify="end" align="center" margin={{ top: '1.25rem' }}>
              <CancelButton label="Cancel" onClick={dismissModal} />
              <SaveButton
                label="Confirm"
                isModal
                onClick={() => {
                  if (isOrder(item)) {
                    const triage = item.triages.find(
                      ({ endDate, _id }) => !endDate && triageId?.toString() === _id?.toString(),
                    )

                    if (triage) {
                      resolveOrderTriageMutation({ variables: { orderId: item._id, triageId: triage._id || '' } })
                    }
                  } else {
                    const triage = item.triages.find(
                      ({ endDate, _id }) => !endDate && triageId?.toString() === _id?.toString(),
                    )

                    if (triage) {
                      resolveCopayTriageMutation({
                        variables: { copayRequestId: item._id, triageId: triage._id || '' },
                      })
                    }
                  }
                }}
              />
            </Box>
          </ModalWrapper>
        ))
      }
    />
  )
}

export default ResolveTriageButton
