import { Button } from '@truepill/react-capsule'
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg'
import PrintPickSlipModal from 'modals/PrintPickSlipModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled, { css } from 'styled-components'
import { capsulePrimaryColorDark, capsulePrimaryColorLight, capsuleDarkBlue } from 'styles/styleVariables'

type PrintPickSlipButtonProps = { orderId: string }

const PrintPickSlipButton = ({ orderId }: PrintPickSlipButtonProps): JSX.Element => {
  const { showModal } = useModalContext()

  const showPrintPickSlipModal = () => {
    showModal(() => <PrintPickSlipModal orderIDs={[orderId]} />)
  }

  return (
    <ButtonWrapper>
      <Button size="xs" variant="primary-outline" onClick={showPrintPickSlipModal}>
        {<PrintIcon />} Print pick slip
      </Button>
    </ButtonWrapper>
  )
}

const StyledButton = css`
  border-radius: 8px;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  svg {
    fill: ${capsulePrimaryColorDark};
    margin-right: 0.25rem;
    min-width: 16px;
  }
  :hover {
    svg {
      fill: ${capsulePrimaryColorLight};
    }
  }
  :disabled {
    svg {
      fill: ${capsuleDarkBlue};
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 0.625rem;
  ul {
    width: auto;
    right: 0;
    white-space: nowrap;
  }
  > button {
    ${StyledButton}
  }
`

export default PrintPickSlipButton
