import { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import type { PrescriberAddressWithSupervisor } from '@truepill/tpos-types'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as InventoryIcon } from 'assets/icons/inventory.svg'
import ActionButton, { SaveButton } from 'components/ActionButton'
import AddressEntry from 'components/AddressEntry'
import AddressForm from 'components/AddressForm'
import IconWrapper from 'components/IconWrapper'
import LoadingSpinner from 'components/Loading'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { CancelButton, FilledHeader } from 'components/PageStructure'
import PrescriberSearchOptions from 'components/PrescriberSearch'
import { ListRowLabel, ListRowValue, StyledTextInput } from 'components/RXTable'
import UserInitialsBadge from 'components/UserInitialsBadge'
import { UPDATE_PRESCRIBER_PRACTICE, GET_PRESCRIBER_PRACTICE_CHANGES } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import moment from 'moment'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled, { css } from 'styled-components'
import {
  bodyPrimaryColor,
  bodySecondaryColor,
  contrastBackgroundColor,
  primaryBackgroundColor,
  primaryColor,
} from 'styles/styleVariables'
import type { Address, Prescriber, User } from 'types'
import { formatPhoneForDB, validatePractice } from 'utils'

type SelectProps = {
  prescriber: Prescriber
  prescriberId: string
  prescriberNpi: string
}

type EditPracticeModalProps = {
  address: PrescriberAddressWithSupervisor
  prescriber: Prescriber
}

const EditPracticeModal = ({ address, prescriber }: EditPracticeModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  const {
    tokenContext: { id: userId, isAdmin },
  } = usePlusClient()
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()
  const [supervisor, setSupervisor] = useState({
    id: address.supervisor?._id || '',
    firstName: address.supervisor?.firstName || '',
    lastName: address.supervisor?.lastName || '',
    npi: address.supervisor?.npi || '',
  })
  const [practiceName, setPracticeName] = useState<string>(address.businessName || '')
  const [phoneNumber, setPhoneNumber] = useState<string>(address.phone || '')
  const [faxNumber, setFaxNumber] = useState<string>(address.fax || '')
  const [practiceAddress, setPracticeAddress] = useState<Address>({
    street1: address.street1,
    street2: address.street2,
    city: address.city,
    state: address.state,
    zip: address.zip,
    country: address.country,
  })
  const [newArchiveStatus, setNewArchiveStatus] = useState<boolean>(address.isArchived || false)

  const isSurescriptsPractice = !!address.spi
  const nameInput = (
    <StyledTextInput
      placeholder="Practice name..."
      value={practiceName}
      onChange={event => setPracticeName(event.target.value)}
    />
  )
  const addressInput = (
    <StyledAddressForm
      {...practiceAddress}
      onChange={(key: string, value: string) => {
        setPracticeAddress({
          ...practiceAddress,
          [key]: value,
        })
      }}
    />
  )
  const phoneInput = (
    <StyledTextInput
      placeholder="Phone number..."
      value={phoneNumber}
      onChange={event => setPhoneNumber(event.target.value)}
    />
  )
  const faxInput = (
    <StyledTextInput
      placeholder="Fax number..."
      value={faxNumber}
      onChange={event => setFaxNumber(event.target.value)}
    />
  )

  const handleSelectOption = (selectProps: SelectProps) => {
    setSupervisor({
      id: selectProps.prescriberId,
      firstName: selectProps.prescriber.firstName,
      lastName: selectProps.prescriber.lastName,
      npi: selectProps.prescriberNpi,
    })
  }

  const handleUnselectSupervisor = () => {
    setSupervisor({ id: '', firstName: '', lastName: '', npi: '' })
  }

  const supervisorDisplay = useMemo(() => {
    const supervisorCard = (
      <StyledSupervisor>
        {isAdmin() && <StyledCloseButton onClick={handleUnselectSupervisor}>X</StyledCloseButton>}
        <strong>
          {supervisor.firstName} {supervisor.lastName}
        </strong>
        <div>
          <strong>NPI:</strong>
          {supervisor.npi}
        </div>
      </StyledSupervisor>
    )

    if (!isAdmin()) {
      return supervisorCard
    }

    return supervisor.id.length > 0 ? supervisorCard : <PrescriberSearchOptions isModal onSelect={handleSelectOption} />
  }, [isAdmin, supervisor])

  const { data, loading: loadingChangeLogs } = useQuery(GET_PRESCRIBER_PRACTICE_CHANGES, {
    variables: { prescriberId: prescriber._id, prescriberPracticeId: address._id },
  })

  const changeLogs: ChangeLog[] = data?.getPrescriberPracticeChanges || []
  const showEditedByBlock = !isSurescriptsPractice || changeLogs.length !== 0

  const [updatePractice] = useMutation(UPDATE_PRESCRIBER_PRACTICE, {
    onCompleted() {
      showSuccessToast('Prescriber practice successfully edited.')
    },
    onError(e) {
      showErrorToast('Failed to update the prescriber practice: ' + e.message)
    },
  })

  const wrapperStyles = showEditedByBlock && isSurescriptsPractice ? WideModalWrapperStyles : ModalWrapperStyles

  return (
    <ModalWrapper id="EditPracticeModal" styles={wrapperStyles}>
      <ModalHeader>
        <IconContainer>
          <IconWrapper>
            <EditIcon fill={bodyPrimaryColor} />
          </IconWrapper>
        </IconContainer>
        <h2>Edit Practice</h2>
      </ModalHeader>
      <PracticeContent>
        <PracticeDataContainer>
          <>
            {isSurescriptsPractice && (
              <>
                <PracticeHeaderDataRow>
                  <SurescriptsHeaderDataColumn>
                    <FilledHeader>Surescripts</FilledHeader>
                  </SurescriptsHeaderDataColumn>
                  <TruepillHeaderDataColumn>
                    <FilledHeader>Truepill</FilledHeader>
                  </TruepillHeaderDataColumn>
                </PracticeHeaderDataRow>
                <PracticeDataRow>
                  <SurescriptsDataColumn>
                    <RXDataRow>
                      <ListRowLabel>Practice name</ListRowLabel>
                      <ListRowValue>{address.businessName}</ListRowValue>
                    </RXDataRow>
                  </SurescriptsDataColumn>
                  <TruepillDataColumn>{nameInput}</TruepillDataColumn>
                </PracticeDataRow>
                <PracticeDataRow>
                  <SurescriptsDataColumn>
                    <RXDataRow>
                      <ListRowLabel>Address</ListRowLabel>
                      <ListRowValue>
                        <AddressEntry inline address={address} />
                      </ListRowValue>
                    </RXDataRow>
                  </SurescriptsDataColumn>
                  <TruepillDataColumn>{addressInput}</TruepillDataColumn>
                </PracticeDataRow>
                <PracticeDataRow>
                  <SurescriptsDataColumn>
                    <RXDataRow>
                      <ListRowLabel>Phone number</ListRowLabel>
                      <ListRowValue>{address.phone}</ListRowValue>
                    </RXDataRow>
                  </SurescriptsDataColumn>
                  <TruepillDataColumn>{phoneInput}</TruepillDataColumn>
                </PracticeDataRow>
                <PracticeDataRow>
                  <SurescriptsDataColumn>
                    <RXDataRow>
                      <ListRowLabel>Fax number</ListRowLabel>
                      <ListRowValue>{address.fax}</ListRowValue>
                    </RXDataRow>
                  </SurescriptsDataColumn>
                  <TruepillDataColumn>{faxInput}</TruepillDataColumn>
                </PracticeDataRow>
              </>
            )}
            {!isSurescriptsPractice && (
              <>
                <FilledHeader>Truepill</FilledHeader>
                <FormSection>
                  <FormLabel>Practice name (optional)</FormLabel>
                  {nameInput}
                </FormSection>
                <FormSection>
                  <FormLabel>Address</FormLabel>
                  {addressInput}
                </FormSection>
                <FormSection>
                  <FormLabel>Phone</FormLabel>
                  {phoneInput}
                </FormSection>
                <FormSection>
                  <FormLabel>Fax (optional)</FormLabel>
                  {faxInput}
                </FormSection>
                <FormSection>
                  <FormLabel>Prescriber</FormLabel>
                  {supervisorDisplay}
                </FormSection>
              </>
            )}
          </>
        </PracticeDataContainer>
        {showEditedByBlock && <EditedByBlock changeLogs={changeLogs} loading={loadingChangeLogs} />}
      </PracticeContent>
      <ButtonsContainer>
        <StyledActionButton
          icon={
            <IconWrapper>
              <InventoryIcon fill={newArchiveStatus !== address.isArchived ? primaryBackgroundColor : 'black'} />
            </IconWrapper>
          }
          archiveChange={newArchiveStatus !== address.isArchived}
          hotKey="a"
          label={address.isArchived ? 'Unarchive practice' : 'Archive practice'}
          onClick={() => setNewArchiveStatus(!newArchiveStatus)}
        />
      </ButtonsContainer>
      <ButtonsContainer>
        <CancelButton
          label={'Cancel'}
          onClick={() => {
            dismissModal()
          }}
        />
        <SaveButton
          disabled={!validatePractice(practiceAddress, phoneNumber || '', faxNumber || '')}
          label="Update"
          isModal
          onClick={async () => {
            // eslint-disable-next-line
            await updatePractice({
              variables: {
                userId,
                prescriberId: supervisor.id,
                npi: prescriber.npi,
                stateLicense: prescriber.stateLicense,
                practice: {
                  _id: address._id,
                  spi: address.spi,
                  surescriptsKey: address.surescriptsKey,
                  isActive: address.isActive,
                  serviceLevel: address.serviceLevel,
                  address: practiceAddress,
                  phone: formatPhoneForDB(phoneNumber),
                  ...(practiceName && { name: practiceName }),
                  ...(faxNumber && { fax: formatPhoneForDB(faxNumber) }),
                  isArchived: newArchiveStatus,
                },
              },
              refetchQueries: ['getSyncedPrescriber', 'getPrescriberPracticeChanges'],
            })

            dismissModal()
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

interface ChangeLog {
  _id: string
  createdAt: string
  user: User
  userId: string
  prescriberId: string
  prescriberPracticeId: string
  change: {
    field: string
    oldValue: string
    newValue: string
  }
}

type EditedByBlockProps = { changeLogs: ChangeLog[]; loading: boolean }

const EditedByBlock = ({ changeLogs, loading }: EditedByBlockProps): JSX.Element => {
  const content = loading ? (
    <LoadingSpinnerContainer>
      <LoadingSpinner />
    </LoadingSpinnerContainer>
  ) : (
    changeLogs.map(({ user, createdAt, change }, i) => (
      <EditedByRow key={i}>
        <EditedByRowTopSection>
          <UserField>
            <BadgeContainer>
              <UserInitialsBadge user={user} />
            </BadgeContainer>
            <UserName>
              {user?.firstName} {user?.lastName}
            </UserName>
          </UserField>
          <Date>{moment(createdAt).format('MM/DD/YYYY, h:mmA')}</Date>
        </EditedByRowTopSection>
        {change.field && (
          <EditedByRowBottomSection>{`changed ${change.field} ${change.oldValue ? `from ${change.oldValue}` : ''} to ${
            change.newValue
          }`}</EditedByRowBottomSection>
        )}
      </EditedByRow>
    ))
  )

  return (
    <EditedByContainer>
      <FilledHeader>Edited By</FilledHeader>
      {content}
    </EditedByContainer>
  )
}

const StyledCloseButton = styled.div`
  position: absolute;
  background: #eeeddd;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StyledSupervisor = styled.div`
  background-color: #eeeeee;
  width: fit-content;
  padding: 16px;
  position: relative;
`

const EditedByContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 38rem;
  margin-left: 0.625rem;
`

const EditedByRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0.8rem;
`

const EditedByRowTopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const EditedByRowBottomSection = styled.div`
  font-size: 0.8rem;
`

const UserField = styled.div`
  display: flex;
  align-items: center;
`

const BadgeContainer = styled.span`
  font-size: 1.25rem;
  margin-right: 0.4rem;
`

const UserName = styled.p`
  font-weight: 500;
`

const Date = styled.p`
  color: ${bodySecondaryColor};
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0.8rem;
  justify-content: center;
  align-items: center;
  svg {
    height: 25px;
  }
`

const ModalWrapperStyles = css`
  width: 50rem;
`

const WideModalWrapperStyles = css`
  width: 68rem;
`

const StyledActionButton = styled(ActionButton)<{ archiveChange: boolean }>`
  background-color: ${props => (props.archiveChange ? primaryColor : primaryBackgroundColor)};
  color: ${props => (props.archiveChange ? primaryBackgroundColor : bodyPrimaryColor)};
`

const PracticeContent = styled.div`
  margin-top: 0.625rem;
  display: flex;
`

const IconContainer = styled.div`
  padding-top: 0.15rem;
`
const PracticeDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
`

const PracticeDataColumn = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SurescriptsDataColumn = styled.div`
  width: 60%;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  ${PracticeDataColumn}
`

const TruepillDataColumn = styled.div`
  width: 40%;
  padding-left: 1.6rem;
  padding-right: 0.6rem;
  ${PracticeDataColumn}
`

const SurescriptsHeaderDataColumn = styled.div`
  width: 60%;
  ${PracticeDataColumn}
`

const TruepillHeaderDataColumn = styled.div`
  width: 40%;
  padding-left: 0.6rem;
  ${PracticeDataColumn}
`

const PracticeDataRow = styled.div`
  display: flex;
  width: 100%;
  :nth-child(2) {
    margin-top: 0.425rem;
  }
  :nth-child(n + 2) {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  :nth-of-type(odd) {
    background-color: ${contrastBackgroundColor};
  }
`

const PracticeHeaderDataRow = styled(PracticeDataRow)`
  background-color: transparent !important;
`

const RXDataRow = styled.ul`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [label] 7rem [value] 1fr;
  padding: 0.5rem 0.3125rem;
`

const StyledAddressForm = styled(AddressForm)`
  margin: 0;
`

const FormLabel = styled.p`
  padding: 0.625rem 0;
  font-weight: bold;
`

const FormSection = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
`

export default EditPracticeModal
