import ActionButton from 'components/ActionButton'
import { ActionBar, FilledHeadingStyle } from 'components/PageStructure'
import SearchInput from 'components/SearchInput'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import {
  contrastBackgroundColor,
  primaryBackgroundColor,
  primaryColor,
  primaryColorLight,
  bodyPrimaryColor,
} from 'styles/styleVariables'

const TruncateTextPosition = css`
  display: block;
  margin-top: 0.5rem;
`

const PracticeAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 0.25rem;
  flex: 1;
  margin-bottom: 1rem;
  width: 100%;
`

const StyledActionBar = styled(ActionBar)`
  align-items: flex-end;
  grid-template-columns: [meta] 0.68fr [actions] 1.5fr;
  padding-left: 0;
  width: 100%;
  padding-right: 0;
`

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
  max-width: 14rem;
`

const PaddingContainer = styled.div`
  height: 500px;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 3rem;
`

const StyledLink = styled.a`
  cursor: pointer;
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
`

const PracticeAddressRow = styled.ul<{ isArchived?: boolean }>`
  display: grid;
  grid-template-rows: [content] 2.5rem;
  grid-template-columns:
    [practiceName] minmax(6rem, 0.6fr)
    [practiceAddress] minmax(10rem, 1.5fr)
    [phoneNumber] minmax(5rem, 13rem)
    [faxNumber] minmax(11rem, 1fr)
    [spi] minmax(11rem, 1fr)
    [supervisingPhysician] minmax(11rem, 1fr)
    [serviceLevel] minmax(11rem, 1fr)
    [status] minmax(5rem, 0.4fr);
  padding-left: 1rem;
  padding-right: 0rem;
  margin-bottom: 0.25rem;
  text-decoration: ${props => (props.isArchived === true ? 'line-through' : 'none')};
`

const TitlePracticeAddressRow = styled(PracticeAddressRow)`
  :first-of-type {
    ${FilledHeadingStyle}
  }
  font-weight: 500;
  padding-right: 0rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  padding-left: 0.9rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`

const PracticeAddressRowCell = styled.li`
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const NewPracticeLocationControls = styled.div`
  grid-column: actions;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const SearchLabel = styled.label`
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
`

const StyledActionButton = styled(ActionButton)<{ showArchive?: boolean }>`
  background-color: ${props => (props.showArchive ? primaryColor : primaryBackgroundColor)};
  color: ${props => (props.showArchive ? primaryBackgroundColor : bodyPrimaryColor)};
`

const SearchControls = styled.div`
  grid-column: meta;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const PracticeName = styled(PracticeAddressRowCell)`
  grid-column: practiceName;
  ${TruncateTextPosition};
`

const PracticeAddress = styled(PracticeAddressRowCell)`
  grid-column: practiceAddress;
  ${TruncateTextPosition};
`

const PhoneNumber = styled(PracticeAddressRowCell)`
  grid-column: phoneNumber;
  ${TruncateTextPosition};
`

const FaxNumber = styled(PracticeAddressRowCell)`
  grid-column: faxNumber;
  ${TruncateTextPosition};
`

const Spi = styled(PracticeAddressRowCell)`
  grid-column: spi;
  ${TruncateTextPosition};
`

const SupervisingPhysician = styled(PracticeAddressRowCell)`
  grid-column: supervisingPhysician;
  ${TruncateTextPosition}
`

const ServiceLevel = styled(PracticeAddressRowCell)`
  grid-column: serviceLevel;
  ${TruncateTextPosition};
`

const Status = styled(PracticeAddressRowCell)`
  grid-column: status;
  ${TruncateTextPosition};
`

export {
  PracticeAddressContainer,
  StyledActionBar,
  StyledSearchInput,
  PaddingContainer,
  StyledLink,
  PracticeAddressRow,
  TitlePracticeAddressRow,
  NewPracticeLocationControls,
  SearchLabel,
  StyledActionButton,
  SearchControls,
  PracticeName,
  PracticeAddress,
  PhoneNumber,
  FaxNumber,
  Spi,
  ServiceLevel,
  Status,
  SupervisingPhysician,
}
