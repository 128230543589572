import { useState, useEffect, useCallback, useMemo, memo } from 'react'
import { useMutation } from '@apollo/client'
import { Box, Button, Header, Modal } from '@truepill/react-capsule'
import type { DURSubjectCategories, PatientSurveyInput, Medispan } from '@truepill/tpos-types'
import { UPDATE_DUR_SCREEN } from 'gql'
import useLogs from 'hooks/navigation/useLogs'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { debounce } from 'lodash'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import styled from 'styled-components'
import type { Log } from 'types'
import DurEditingSection from './Form/DurEditingSection'
import EditHistory from './Form/EditsHistory'
import SurveySection from './Form/SurveySection'
import type { DurMappingFormValues } from './types'

interface EditUracModalProps {
  orderId: string
  survey: PatientSurveyInput
  currentDurInputs: DURSubjectCategories
  isOpen: boolean
  onClose: () => void
  onURACUpdateCompleted?: () => void
}

const DurInputMappingsModal = memo(
  ({ isOpen, orderId, currentDurInputs, survey, onClose, onURACUpdateCompleted }: EditUracModalProps): JSX.Element => {
    const { logs, loading: isFetchingEdits, error: errorFetchingEdits } = useLogs({ orderId })
    const showSuccesToast = useSuccessToast()
    const showErrorToast = useErrorToast()
    const [isUpdateDisabled, setIsUpdateDisabled] = useState(true)
    const [isEditSectionVisible, setIsEditSectionVisible] = useState(false)

    const [updateDUR, { loading: durScreeningLoading }] = useMutation(UPDATE_DUR_SCREEN, {
      onCompleted: () => {
        showSuccesToast('URAC updated')
        onURACUpdateCompleted?.()
        onClose()
      },
      onError: () => {
        showErrorToast('An error ocurred while updating URAC')
      },
      refetchQueries: ['getBasicOrder'],
    })

    const defaultValues = useMemo(
      () => ({
        allergies: getDefaultAllergies(currentDurInputs),
        conditions: getDefaultConditions(currentDurInputs),
        medications: getDefaultMedications(currentDurInputs),
      }),
      [currentDurInputs],
    )

    const useFormProps = useForm<DurMappingFormValues>({
      defaultValues,
      mode: 'onBlur',
    })

    const { control, handleSubmit, watch } = useFormProps

    const allergiesFieldArray = useFieldArray({ name: 'allergies', control })
    const medicationsFieldArray = useFieldArray({ name: 'medications', control })
    const conditionsFieldArray = useFieldArray({ name: 'conditions', control })

    const appendHighlight = useCallback(
      (type: 'allergies' | 'medications' | 'conditions', highlight: string) => {
        if (type === 'allergies') {
          const existingHighlight = allergiesFieldArray.fields.some(field => field.term === highlight)
          if (!existingHighlight) {
            allergiesFieldArray.append({ term: highlight })
          }
        } else if (type === 'medications') {
          const existingHighlight = medicationsFieldArray.fields.some(field => field.term === highlight)
          if (!existingHighlight) {
            medicationsFieldArray.append({ term: highlight })
          }
        } else if (type === 'conditions') {
          const existingHighlight = conditionsFieldArray.fields.some(field => field.term === highlight)
          if (!existingHighlight) {
            conditionsFieldArray.append({ term: highlight })
          }
        }
      },
      [allergiesFieldArray, medicationsFieldArray, conditionsFieldArray],
    )

    const removeHighlight = useCallback(
      (type: 'allergies' | 'medications' | 'conditions', highlight: string) => {
        if (type === 'allergies') {
          const indexToRemove = allergiesFieldArray.fields.findIndex(field => field.term === highlight)
          if (indexToRemove !== -1) {
            allergiesFieldArray.remove(indexToRemove)
          }
        } else if (type === 'medications') {
          const indexToRemove = medicationsFieldArray.fields.findIndex(field => field.term === highlight)
          if (indexToRemove !== -1) {
            medicationsFieldArray.remove(indexToRemove)
          }
        } else if (type === 'conditions') {
          const indexToRemove = conditionsFieldArray.fields.findIndex(field => field.term === highlight)
          if (indexToRemove !== -1) {
            conditionsFieldArray.remove(indexToRemove)
          }
        }
      },
      [allergiesFieldArray, medicationsFieldArray, conditionsFieldArray],
    )

    const handleUpdate = (data: DurMappingFormValues) => {
      const sanitizedData = {
        ...data,
        allergies: data.allergies
          .filter(item => item.term)
          .map(({ medispanData }) => ({ ...medispanData, __typename: undefined })),
        conditions: data.conditions
          .filter(item => item.term)
          .map(({ medispanData }) => ({ ...medispanData, __typename: undefined })),
        medications: data.medications
          .filter(item => item.term)
          .map(({ medispanData }) => ({ ...medispanData, __typename: undefined })),
      }

      updateDUR({
        variables: {
          orderId,
          ...sanitizedData,
        },
      })
    }

    const allFormFields = watch()

    useEffect(() => {
      // Define a debounced function that checks if there is incomplete Medispan data
      // This function is debounced to avoid frequent updates, particularly when
      // the form data is changing rapidly due to user input
      const debouncedCheckIncompleteData = debounce((formData: DurMappingFormValues) => {
        const hasMissingData = (items: { term: string; medispanData?: Medispan.DURSubject }[]) =>
          items.some(item => (item.term && !item.medispanData) || (!item.term && item.medispanData))

        const isIncomplete =
          hasMissingData(formData.allergies) ||
          hasMissingData(formData.conditions) ||
          hasMissingData(formData.medications)

        setIsUpdateDisabled(isIncomplete)
      }, 300)

      debouncedCheckIncompleteData(allFormFields)

      return () => debouncedCheckIncompleteData.cancel()
    }, [allFormFields])

    const edits = useMemo(
      () =>
        logs?.filter((log: Log) => {
          return log.event === 'change' && log.change?.field?.includes('durInputs')
        }),
      [logs],
    )

    return (
      <Modal
        overlayCss={{ zIndex: 999 }}
        css={{
          width: '72rem',
          height: 'auto',
          maxHeight: '80vh',
          maxWidth: 'none !important',
          padding: '1.5rem',
        }}
        isOpen={isOpen}
        onDismiss={onClose}
      >
        <Header variant="2xl" id="title" bold css={{ marginBottom: '1rem' }}>
          Edit URAC
        </Header>
        <FormProvider {...useFormProps}>
          <form onSubmit={handleSubmit(handleUpdate)}>
            <Content>
              <SurveySection
                totalEdits={edits?.length ?? 0}
                survey={survey}
                defaultHighlights={{
                  allergies: watch('allergies')?.map(({ term }) => term) ?? [],
                  conditions: watch('conditions')?.map(({ term }) => term) ?? [],
                  medications: watch('medications')?.map(({ term }) => term) ?? [],
                }}
                onEditHistoryClick={() => setIsEditSectionVisible(true)}
                onAppendHighlight={appendHighlight}
                onRemoveHighlight={removeHighlight}
              />
              {isEditSectionVisible ? (
                <EditHistory
                  isFetchingEdits={isFetchingEdits}
                  edits={edits}
                  errorFetchingEdits={errorFetchingEdits}
                  onExitClick={() => setIsEditSectionVisible(false)}
                />
              ) : (
                <DurEditingSection />
              )}
            </Content>
            <Buttons>
              <Button type="button" variant="primary-text" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isUpdateDisabled || durScreeningLoading}>
                Update
              </Button>
            </Buttons>
          </form>
        </FormProvider>
      </Modal>
    )
  },
)

function getDefaultConditions(durInputs: DURSubjectCategories) {
  const conditions: DurMappingFormValues['conditions'] = durInputs.conditions
    ?.filter(a => a.patientSurveyInput)
    .map(a => ({
      term: a.patientSurveyInput ?? '',
      medispanData: { ...a } as DurMappingFormValues['conditions'][number]['medispanData'],
    }))

  conditions.push({ term: '' })

  return conditions
}

function getDefaultAllergies(durInputs: DURSubjectCategories) {
  const allergies: DurMappingFormValues['allergies'] = durInputs.allergies
    ?.filter(a => a.patientSurveyInput)
    .map(a => ({ term: a.patientSurveyInput ?? '', medispanData: { ...a } }))

  allergies.push({ term: '' })

  return allergies
}

function getDefaultMedications(durInputs: DURSubjectCategories) {
  const medications: DurMappingFormValues['medications'] = durInputs.medications
    ?.filter(a => a.patientSurveyInput)
    .map(a => ({ term: a.patientSurveyInput ?? '', medispanData: { ...a } }))

  medications.push({ term: '' })

  return medications
}

const Buttons = styled(Box)`
  display: flex;
  gap: 0.5rem;
  justify-content: end;
  padding-top: 1.25rem;
`

const Content = styled(Box)`
  min-height: 29.125rem;
  font-family: Lato;
  display: grid;
  grid-template-columns: 3fr 7fr;
`

export default DurInputMappingsModal
