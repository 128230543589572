import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_DUR_CONDITIONS } from 'gql'
import type { FuzzySearchProps } from 'modals/DurInputMappingsModal/types'
import type { DURSubject } from 'types'
import FuzzySearchAutoComplete from './FuzzySearchAutocomplete'

const ConditionsFuzzySearch = (props: FuzzySearchProps) => {
  const { searchTerm } = props
  const [newSearchTerm, setNewSearchTerm] = useState(searchTerm)
  const { data, error } = useQuery<{ getConditions: DURSubject[] }>(GET_DUR_CONDITIONS, {
    skip: !newSearchTerm,
    variables: { conditions: newSearchTerm },
  })

  useEffect(() => {
    setNewSearchTerm(searchTerm)
  }, [searchTerm])

  return (
    <FuzzySearchAutoComplete
      {...props}
      suggestions={data?.getConditions ?? []}
      error={error}
      searchTerm={newSearchTerm}
      onChange={setNewSearchTerm}
    />
  )
}

export default ConditionsFuzzySearch
